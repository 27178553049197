import React from "react";
import { useLocation } from "react-router-dom";

// components
import CardResetPassword from "components/Cards/Auth/CardResetPassword.js";

// texts
import cardresetpassword from "_texts/auth/cards/cardresetpassword.js";

export default function Reset_public() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  //check if logged in and redirect to dashboard if so

  return (
    <>
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-black bg-no-repeat bg-full"
          ></div>
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-4/12 px-4">
                <CardResetPassword {...cardresetpassword} />
              </div>
            </div>
          </div>

        </section>
      </main>
    </>
  );
}
