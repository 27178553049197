import React, {useState, useEffect} from "react";
import { Route, Switch, Redirect, withRouter} from "react-router-dom";
import PropTypes from "prop-types";

// //admin
import ProfileManager from "../site/admin/musicgarden/ProfileManager.js";


// //temp
import Greetings from "../site/temp/Greetings.js";





const NoAccessLayout = ({match: { url }}) =>{


  return(
    <Switch>
      <Route
        path={`${url}/greetings`}
        exact
        render={() => (
          <React.Fragment>
            <div className="pt-17">
              <Greetings />
            </div>
          </React.Fragment>
        )}
      />
      <Route
        path={`${url}/profilemanager`}
        exact
        render={() => (
          <React.Fragment>
            <div className="pt-17">
              <ProfileManager />
            </div>
          </React.Fragment>
        )}
      />
      <Redirect from="/musicgarden/*" to="/greetings" />
    </Switch>
  );
}; //END of this component

NoAccessLayout.propTypes = { match: PropTypes.object.isRequired }

export default withRouter(NoAccessLayout)
