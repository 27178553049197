const textProps = {

  copyright: {
    href: "",
    children: "Copyright © MktShare Inc. " + new Date().getFullYear(),
  },
  links: [
    {
      href: "/#meminimus/rules",
      children: "Rules",
    },
    {
      href: "/#meminimus/terms",
      children: "Terms",
    },
    {
      href: "https://www.indiegogo.com/project/preview/d0a832e6#/",
      children: "Support",
    },
  ],
};
export default textProps;
