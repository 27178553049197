import React, {useEffect} from "react";
import { useLocation } from "react-router-dom"

//components
import Teams1 from "components/Sections/Presentation/Teams/Teams1.js";
import CardFullTable from "components/Cards/Admin/CardFullTable.js";
import CardSettingsLarge from "components/Cards/Admin/CardSettingsLarge.js";
import Button from "components/Elements/Button";
import FooterAdmin from "components/Footers/Admin/FooterAdmin.js";

//texts as props
import teams1 from "_texts/presentation/teams/teams1.js";
import cardfulltable from "_texts/admin/cards/cardfulltable.js";
import cardsettingslarge from "_texts/admin/cards/cardsettingslarge.js";

import footeradmin from "_texts/admin/footers/footeradmin.js";

const hottopicbutton = { children: "Hot Topic", color: "dark", outline: false };
const marketplacebutton = { children: "Marketplace", color: "emerald" };


const Dashboard = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  //call to fileMusicMember
  //update badge with result

/*
          NOTE:
            Teams1 is Curator's top 5
            CardFullTable is Curator's Table
            CardSettingsLarge is Exhibition Submission form
*/

  return(
    <div className="w-full">
      <Teams1 {...teams1} />
      <div className="pt-12 px-4">
        <CardFullTable {...cardfulltable} color="light" />
      </div>
      <div className="w-full px-4">
        <CardSettingsLarge {...cardsettingslarge} />
      </div>
      <div className="px-12 text-center flex justify-between items-center">
        <Button {...hottopicbutton} />
        <Button {...marketplacebutton} />
      </div>
      <div className="mt-4">
        <FooterAdmin {...footeradmin} />
      </div>
    </div>
  );
}

export default Dashboard
