import React from "react";
import { useLocation } from "react-router-dom";

// components
import CardLoginRegister from "components/Cards/Auth/CardLoginRegister.js";

// texts
import cardregister from "_texts/auth/cards/cardregister.js";


export default function Register1() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div className="absolute top-0 w-full h-full bg-black bg-no-repeat bg-full">
          </div>
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-5/12 px-4">
                <CardLoginRegister {...cardregister} />
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
