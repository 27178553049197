import {useEffect} from "react"
import {atom, selector, useRecoilState} from "recoil"
import { API, graphqlOperation } from 'aws-amplify';



export const musicBadge = atom({
  key: "MUSIC_BADGE",
  default: {
    musicGardenID: null,
    curatorID: null,
    profileImage: null,
    accessCode: null,
    handle: null
  },
})

export const showMusicBadge = selector({
    key: 'SHOW_MUSIC_BADGE',
    get:({get}) => {
        const badgeInfo = get(musicBadge);
        return badgeInfo
    }
})




export const badge_in_with_musicmember_custodian = async (vars) => {

  const que_musicmember_custodian = `
    query musicmember_custodian($id: ID!) {
      musicmember_custodian($id: $id) {
          musicGardenID
          accessCode
      }
  `;

  const caller = {
    musicGardenID: vars.musicGardenID,
    accessCode: vars.accessCode
  };

  let receptionist_response = {}
  try {
     receptionist_response = await API.graphql(graphqlOperation(que_musicmember_custodian, caller))
    //set return info to badge data
    const official_badge =  {
      musicGardenID: receptionist_response.musicGardenID,
      curatorID: receptionist_response.curatorID,
      profileImage: receptionist_response.profileImage,
      accessCode: receptionist_response.accessCode,
      handle: receptionist_response.handle
    }
    return official_badge
  } catch (error) {
    console.log("RECEPTIONIST_ERROR_RES:", error)
  }
};

export const useMusicBadgeInit = () => {
  const [isBadge, setIsBadge] = useRecoilState(musicBadge)
  useEffect(()=>{
    if (isBadge.musicGardenID){
        const variables = {
          musicGardenID: isBadge.musicGardenID,
          accessCode: isBadge.accessCode
        }
        const official_badge = badge_in_with_musicmember_custodian(variables)
        setIsBadge(official_badge)
    }
  }, [setIsBadge])
  return [isBadge, musicBadge.musicGardenID !== null, setIsBadge]
}
