import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { useSetRecoilState } from "recoil";
import { toast } from 'react-toastify';
import { accessCodeCustodian } from '../../../graphql/mutations';
import { accessCodebyAccessCode } from '../../../graphql/queries';
import "../../../assets/styles/modals.css"

//hoc & hooks
//import {useCurrentFlowUser} from "../../../hooks/use-current-flow-user.hook";
import {useMusicBadgeInit} from "../../../hooks/use-inhouse-music-membership-badge.hook";
import withRedirect from '../../../hoc/withRedirect';

// components
import Button from "../../../components/Elements/Button.js";
import Input from "../../../components/Elements/Input.js";
import Checkbox from "../../../components/Elements/Checkbox.js";

const CardLoginRegister = ({
  set,
  title,
  subtitle,
  inputs,
  socials,
  buttonOFF,
  buttonON,
  tempButton,
  checkbox,
  checkbox1,
  resetPassword,
  createAccount,
  loginAccount,
  setRedirect,
  setRedirectUrl }) => {
  //states
    //Login and register
  const [isCredentials, setIsCredentials] = useState({
    handle: null,
    access_code: null,
    email: null,
    passcode: null,
    password: null,
    confirm_password: null,
    phone: null
  });

  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [isTermsChkd, setIsTermsChkd] = useState(false)
  const [walletReqMsg, setWalletReqMsg] = useState(false)
  const [ isFormError, setIsFormError] = useState({
    handle: null,
    access_code: null,
    email: null,
    passcode: null,
    password: null,
    confirm_password: null,
    phone: null,
    cognito: null,
    wallet: null
  });
  const [isFormErrorArray, setIsFormErrorArray] = useState([]);

useEffect(() => {
  if (set === "login") {
    setIsTermsChkd(true)
  }
} ,[set])

useEffect(() => {
  if (set === "login") toast("Hello 👋 please log in", {autoClose: 2500})
  if (set === "register") toast("Hello 👋 please register")
},[])

  //Flow Auth & Music Badge
  //const [user, loggedIn, {signUp, logIn}] = useCurrentFlowUser();
  const  init_MusicBadge = useMusicBadgeInit().[3];

  const handleFormChange = async e => {
    e.preventDefault()
    const {name, value} = e.target;
    switch (name){
      case "email":
        let email = value;
        setIsCredentials({...isCredentials, email})
      break;
      case "passcode":
        let passcode = value;
        setIsCredentials({...isCredentials, passcode})
      break;
      case "password":
        let password = value;
        setIsCredentials({...isCredentials, password})
      break;
      case "confirm_password":
        let confirm_password = value;
        setIsCredentials({...isCredentials, confirm_password})
      break;
      case "handle":
        let handle = value;
        setIsCredentials({...isCredentials, handle})
      break;
      case "phone_number":
        let phone = value;
        setIsCredentials({...isCredentials, phone})
      break;
      case "access_code":
        let access_code = value;
        setIsCredentials({...isCredentials, access_code})
      break;
      default:
      return
    };
    if (set === "login") return
    chkUserInput(name, value)
  }; //END handleFormChange

  //reset all state(s)
  const authForm = document.getElementById("authPgsForm")
  const resetState = () => {
    authForm.reset()
    setIsCredentials({
      handle: null,
      access_code: null,
      email: null,
      passcode: null,
      password: null,
      confirm_password: null,
      phone: null
    })
    setIsFormError({
      handle: null,
      access_code: null,
      email: null,
      passcode: null,
      password: null,
      confirm_password: null,
      phone: null,
      cognito: null,
      wallet: null
    })
    setIsFormErrorArray([]);
    setIsButtonDisabled(true);
    setIsTermsChkd(false);
  };//End reset

  const chkUserInput = (name, value) => {
    //Regular Expressions
    const handleRegex = /^(?=.{5,32})(?!.*\s)(?!.*[@$#,><':;*|`\\/^(){}[\]])(?!.*\.\.).*$/gm;
    const emailRegex = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/gm;
    const passwordRegex =  /^(?=.{8,99})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^$*.[\]{}()?"!@#%&/\\,><':;|_~`])(?!.*\s).*$/gm;
    const phoneRegex = /^(\d{10})+$/gm;

    switch (name) {
      case 'handle':
        isFormError.handle = handleRegex.test(value) ? '' : 'Name{ 5 character min, 32 max, only some spcl 🤗 }';
        break;
      case 'email':
       isFormError.email = emailRegex.test(value) ? '' : 'Email format not valid!';
        break;
        case 'phone_number':
         isFormError.phone = phoneRegex.test(value) ? '' : 'Phone{ 10 digits, no spaces, - or (), must use U.S. NUMBER ex. 5557771234 📱 }';
          break;
      case 'password':
        isFormError.password = passwordRegex.test(value) ? '' : 'Password{ 8 character min, use numbers, Uppercase, lowercase, only some spcl 🤐 }';
        isFormError.confirm_password = value === isCredentials.confirm_password ? '' : 'Password & confirmation must match';
        break;
      case 'confirm_password':
        isFormError.confirm_password = value === isCredentials.password ? '' : 'Password & confirmation must match';
        break;
      default:
        break;
    };
    //create array from error state
      const currentFormErrorArray = [{
        handle: isFormError.handle,
        access_code: isFormError.access_code,
        email: isFormError.email,
        password: isFormError.password,
        confirm_password: isFormError.confirm_password,
        phone: isFormError.phone,
        cognito: isFormError.cognito,
        wallet: isFormError.wallet
      }];
      setIsFormErrorArray(currentFormErrorArray);
    }; //END chkUserInput

    const formatPhone = (phoneNum) => {
      //must begin with + & have country designator for cognito
        const phoneNCountry = "+1" + phoneNum; //only US for now
        return phoneNCountry
    };//END formatPhone


  const handleFormSubmit = async e => {
    e.preventDefault();
    let cognito = null;
    setIsFormError({...isFormError, cognito})
    if (set === "login"){
      //handleSignIn();  --------------------------------------------------------------------- DEMO'd out
      openModal()
      //demoSignIn();
    } else {
      //handleRegister(); --------------------------------------------------------------------- DEMO'd out
      toast("🔒 Registration is Currently Closed")
    };
    resetState();
  }; //END handleFormSubmit
//////////////////////////////////////////////////////////////////////////////////////////////////    SignIn
/////////////////////////////////////////////////////////////////////////  Modal
const [isModalOpen, setIsModalOpen] = useState(false);

const openModal = () => {
  setIsModalOpen(true);
};

const closeModal = () => {
  setIsModalOpen(false);
};

const cognito_error_catcher = (error) => {
  let cognitoDev = error.message;
  console.log("cognitoError", cognitoDev)
  switch (cognitoDev){
    case "User is not confirmed.":
      toast("🔒 member's email not confirmed")
    break;
    default: return
  }
  const cognito = cognitoDev ? "New members must confirm email. If already confirmed, login attempt failed" : null;
  setIsFormError({...isFormError, cognito})
  resetState()
}

//trigger badge setup
const badge_in_trigger_redirect = (thisdata) => {
  let user_shell = {
    musicGardenID: thisdata.sub,
    curatorID: null,
    profileImage: null,
    accessCode: isCredentials.passcode,
    handle: null
  }
  init_MusicBadge(user_shell)
  toast("Entering Music Garden 😶‍🌫️")
  setRedirect(true);
}

const handleGoogleSignIn = async () => {
   // handle Google sign-in logic here
   try {
      // Sign in with Google using the Google Sign-In API
      const authResult = await window.gapi.auth2.getAuthInstance().signIn();

      // Authenticate the user with Cognito using the Google access token
      const idToken = authResult.getAuthResponse().id_token;
      const cognitoUser = await Auth.federatedSignIn(
        "google",
        { token: idToken },
        { identity_provider: "Google" }
      ).then((fwdResult) => {
        badge_in_trigger_redirect(fwdResult)
      });

      // Redirect to the app home page
      window.location.href = "/#meminimus/music_garden/dashboard";
    } catch (err) {
      cognito_error_catcher(err)
    }
   closeModal();
 };

//////////////////////////////////////////////////////////////////////////////////////////////////  - here

  const demoSignIn = async () => {
      const {passcode} = isCredentials;
      console.log("PASSCODE", passcode)
      if (passcode == "flowhackathon"){
        setRedirect(true)
      }
  };

  const handleSignIn = async () => {
      const {email, password} = isCredentials;
        try {
           await Auth.signIn(email, password).then(async result => {
             console.log("Result", result)
             if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
               let user = result;
               let newPassword = password;
              await Auth.completeNewPassword(
                 user,
                 newPassword,
               )
             }
             return result
          }).then((fwdResult) => {
            badge_in_trigger_redirect(fwdResult)
          });
        } catch (err) {
          cognito_error_catcher(err)
          }
          closeModal();
  }; //END handleSignIn
///////////////////////////////////////////////////////////////////////////////////////////////     Register
  const handleRegister = async () => {
    //chk user input
    chkUserInput();
        const {email, password, handle, access_code, phone} = isCredentials;
        const phone_number = formatPhone(phone);
        let code = access_code ? access_code : "noaccesscode"
        try {
         await Auth.signUp({
            email,
            password,
            attributes: {
                phone_number
            }
          }).then( async nxtResponse => {
            // request & accept payment here

          }).then( result => {
            toast(`Search email for Memory's Music Garden and confirm email to secure registration.`, {
              autoClose: false
            })
            setRedirect(true)
          });
          } catch (error) {
            let cognitoDev= error.message;
            console.log("cognito error", cognitoDev)
            const cognito = cognitoDev ? "Registration attempt failed" : null;
            setIsFormError({...isFormError, cognito})
            toast("⛔ could not complete registration")
            resetState()
          };
  }; //END handleRegister

  useEffect(() => {
    if(set === "login"){
      setWalletReqMsg(true);
      //setRedirectUrl(`/musicgarden/director`);
      setRedirectUrl(`/musicgarden/music_dashboard`);
      return
    }
     setRedirectUrl(`/login`);
  }, [setRedirectUrl, set]);

/// button effect
  useEffect(() => {
    if (set === "login") {
      setIsButtonDisabled( !isCredentials.passcode)
    } else {
      setIsButtonDisabled( !isTermsChkd || !isCredentials.email || !isCredentials.password || !isCredentials.handle || !isCredentials.phone
      || isFormError.email || isFormError.password || isFormError.handle || isFormError.confirm_password || isFormError.phone )
    }
  },[set, isTermsChkd, isCredentials.email, isCredentials.passcode, isCredentials.password, isCredentials.handle, isCredentials.phone,
      isFormError.email, isFormError.password, isFormError.handle, isFormError.confirm_password, isFormError.phone ]);



////////////////Scratch-Pad Area Start///////////////////
/*
      //////check variable types
const isString = typeof aString === 'string' || aString instanceof String;
const isNumber = typeof test === 'number' || test instanceof Number;
(typeof booleanValue) // displays "boolean"
(typeof numericalValue) // displays "number"
(typeof stringValue) // displays "string"
(typeof stringObject) // displays "object"
*/
////////////////Scratch-Pad Area End/////////////////////

  return (
    <>
      <div className="relative flex flex-col w-full mb-6 shadow-lg rounded-lg bg-white">
        <div className="mb-0 px-6 py-6">
          <div className="text-center mb-3">
            <h6 className="text-blueGray-500 text-sm font-bold">{title}</h6>
          </div>

            {set === "register" && walletReqMsg === false ? (
              <>
                <div className="text-center">
                  <p>
                    <b>Note</b>: This platform requires a&nbsp;
                    <a href="https://www.onflow.org/faq" target="_blank" rel="noopener noreferrer"><u>Flow</u></a>
                    &nbsp;compatible digital wallet.
                  </p>
                  <p>
                    <em>Please review the wallet guide to learn more.</em>
                  </p>
                </div>
                <div className="text-right mt-6">
                  <Checkbox checked={walletReqMsg} onChange={({ target }) => setWalletReqMsg(target.checked)} {...checkbox1} />
                </div>
              </>
            ):null}
          {walletReqMsg === true ? <hr className="mt-6 border-b-1 border-blueGray-200" /> :null}
        </div>
        {walletReqMsg === true ? (
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
        {isFormErrorArray.map((prop, key)=>{
          return(
            <div key={key} className="text-red-500 text-center mb-3 font-bold">
              <p><small>{prop.handle}</small></p>
              <p><small>{prop.access_code}</small></p>
              <p><small>{prop.email}</small></p>
              <p><small>{prop.phone}</small></p>
              <p><small>{prop.password}</small></p>
              <p><small>{prop.confirm_password}</small></p>
              <p><small>{prop.cognito}</small></p>
              <p><small>{prop.wallet}</small></p>
            </div>
          );
        })}
          <form id="authPgsForm" onSubmit={handleFormSubmit}>
            {inputs.map((prop, key) => {
              return (
                <div key={key} className="relative w-full">
                  <label className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1">
                    {prop.label}
                  </label>
                  <Input onChange={handleFormChange} name={prop.switch} {...prop.input} />
                </div>
              );
            })}
            { set === "login" ? null : (
            <div className="flex justify-between">
              <div className="mt-2">
                <Checkbox checked={isTermsChkd} onChange={({ target }) => setIsTermsChkd(target.checked)} {...checkbox} />
              </div>
              <div className="mt-2 text-sm font-bold text-lightBlue-500">
                 ❔<Link to={checkbox1.linkTo}> {checkbox1.linked} </Link>
              </div>
            </div>)}
            { isButtonDisabled ? (
                <div className="text-center mt-5">
                  <Button type="button" disabled={isButtonDisabled} {...buttonOFF} />
                </div>
              ) : (
                <div className="text-center mt-5">
                  <Button type="reset"  onClick={handleFormSubmit} {...buttonON} />
                </div>
              )}
          </form>
        </div>) : null}

      </div>

      { Object.keys(resetPassword).length === 0 &&
        Object.keys(createAccount).length === 0 &&
        Object.keys(loginAccount).length === 0
       ? null : ( //start 1st falsy
         <div className="flex flex-wrap mt-6 relative">
            <div className="w-1/2 text-left">
              {resetPassword && resetPassword.to ? (
                <Link {...resetPassword} className="text-blueGray-500 ml-2">
                  <small>Forgot password?</small>
                </Link>
              ) : resetPassword && resetPassword.href ? (
                <a {...resetPassword} className="text-blueGray-500 ml-2">
                  <small>Forgot password?</small>
                </a>
              ) : null}
            </div>
            <div className="w-1/2 text-right">
              {createAccount && createAccount.to ? (
                <Link {...createAccount} className="text-blueGray-500 mr-2">
                  <small>Create new account</small>
                </Link>
              ) : createAccount && createAccount.href ? (
                <a {...createAccount} className="text-blueGray-500 mr-2">
                  <small>Create new account</small>
                </a>
              ) : loginAccount && loginAccount.to ? (
                <Link {...loginAccount} className="text-blueGray-500 mr-2">
                  <small>Login to account</small>
                </Link>
              ) : loginAccount && loginAccount.href ? (
                  <a {...loginAccount} className="text-blueGray-500 mr-2">
                    <small>Login to account</small>
                  </a>
                ) : null}
            </div>
          </div>
        )}

        {isModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <div className="button-group">
                <button className="google-btn" onClick={handleGoogleSignIn}>
                  <span className="google-icon"></span>
                  Sign in with Google
                </button>
              </div>
              <form onSubmit={handleSignIn}>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input type="email" id="email" required />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input type="password" id="password" required />
                </div>
                <div className="button-group">
                  <button type="submit">Sign In</button>
                  <button type="cancel-button" onClick={closeModal}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
    </>
  );
}

CardLoginRegister.defaultProps = {
  inputs: [],
  button: {},
  resetPassword: {},
  createAccount: {},
  loginAccount: {},
};

CardLoginRegister.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  // NOTE: the "Reset password" text is allready set
  resetPassword: PropTypes.object,
  // NOTE: the "Create new account" && "Login account" text is allready set
  createAccount: PropTypes.object,
  loginAccount: PropTypes.object,
  // It is represetnted by the props you
  // can pass to our Button component element
  button: PropTypes.object,
  setRedirect: PropTypes.func.isRequired,
  setRedirectUrl: PropTypes.func.isRequired,
  socials: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOf([
        "facebook",
        "twitter",
        "instagram",
        "github",
        "pinterest",
        "youtube",
        "vimeo",
        "slack",
        "dribbble",
        "reddit",
        "tumblr",
        "linkedin",
      ]),
      // It is represetnted by the props you
      // can pass to our Button component element
      // for example you can add an onClick handler
      button: PropTypes.object,
    })
  ),
  // It is represetnted by the props you
  // can pass to our Checkbox component element
  checkbox: PropTypes.object,
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      // It is represetnted by the props you
      // can pass to our Input component element
      // NOTE: if you wish to generate a textarea, you will need to pass
      // // // inside this object >> type: "textarea"
      // // // full example >> input: { type: "textarea" }
      input: PropTypes.object,
    })
  ),
};

export default withRedirect(CardLoginRegister);
