import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { RecoilRoot } from "recoil";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Fade } from './components/Elements/Toast';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
//import * as dotenv from 'dotenv';


//chain(s)
import "./flow/config";

//styles
import "assets/styles/tailwind.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/docs.css";
import "assets/styles/minor.css";

//AppLayout
import AppLayout from "./layouts/AppLayout.js"


Amplify.configure(awsconfig);
//dotenv.config() //../env file



const App = () => {


  return(
    <React.StrictMode>
      <RecoilRoot>
        <HashRouter hashType={"noslash"} basename={"meminimus"}>
          <Switch>
            <Route component={AppLayout}/>
          </Switch>
          <ToastContainer
            position="bottom-left"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHovertransition={Fade}
          />
        </HashRouter>
      </RecoilRoot>
    </React.StrictMode>
  );
};

export default App;
