import React from "react";
import PropTypes from "prop-types";
import Button from "../../../../components/Elements/Button";

// components
import Heading3 from "../../../../components/Headings/Heading3.js";
import InfoAreaUser from "../../../../components/InfoAreas/Presentation/InfoAreaUser.js";

const viewcollectionbutton = { children: "View Collection", color: "indigo", size: "sm" };

export default function Teams1({ heading3, infoAreas }) {
  return (
    <>
      <section>
        <div className="container px-4">
          <div className="mb-18">
            {heading3 && (
              <div>
                <Heading3 {...heading3} />
              </div>
            )}
          </div>
          <div className="ml-24">
            <div className="center flex flex-wrap">
              {infoAreas.map((prop, key) => {
                return (
                  <div
                    className="w-full md:w-6/12 lg:w-2/12 lg:mb-0 mb-12 px-2"
                    key={key}
                  >
                    <InfoAreaUser {...prop} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="mt-8 text-center">
          <Button {...viewcollectionbutton} />
        </div>
      </section>
    </>
  );
}

Teams1.defaultProps = {
  heading3: null,
  infoAreas: [],
};
Teams1.propTypes = {
  // prop to pass to the Heading3 component
  heading3: PropTypes.object,
  // it is represented by the props
  // that can be passed to the InfoAreaUser,
  // so please check that one out
  infoAreas: PropTypes.arrayOf(PropTypes.object),
};
