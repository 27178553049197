import React from "react";
import ReactDOM from "react-dom";
import App from "./App.js";

//Dear God 🙏🏾🧎🏾‍♂️ amen!

ReactDOM.render(
  <App/>,
  document.getElementById("root")
);
