import React from "react";
import PropTypes from "prop-types";

// components
import Button from "../../../components/Elements/Button.js";
import Input from "../../../components/Elements/Input.js";

export default function BatchRequestList({
  title,
  list,
  gradient,
  color,
  image,
  button,
  inputs,
}) {
  const colors = {
    blueGray: "bg-blueGray-500",
    red: "bg-red-500",
    orange: "bg-orange-500",
    amber: "bg-amber-500",
    emerald: "bg-emerald-500",
    teal: "bg-teal-500",
    lightBlue: "bg-lightBlue-500",
    indigo: "bg-indigo-500",
    purple: "bg-purple-500",
    pink: "bg-pink-500",
  };
  return (
    <>
      <h6 className=" text-6xl font-bold leading-tight uppercase mb-2">{title}</h6>
      <ul className="my-6 mx-4 list-none">
      <form>
        {inputs.map((prop, key) => {
          return (
            <div key={key} className="relative w-full">
              <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                {prop.label}
              </label>
              <Input {...prop.input} />
            </div>
          );
        })}
        <div className="text-center mt-6">
          <Button {...button} />
        </div>
      </form>
      </ul>
    </>
  );
}
BatchRequestList.defaultProps = {
  list: [],
  button: {},
};
BatchRequestList.propTypes = {
  image: PropTypes.string,
  head: PropTypes.string,
  title: PropTypes.string,
  // props to pass to the Button component
  button: PropTypes.object,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  color: PropTypes.oneOf([
    "blueGray",
    "red",
    "orange",
    "amber",
    "emerald",
    "teal",
    "lightBlue",
    "indigo",
    "purple",
    "pink",
  ]),
};
