import React from "react";
import { useLocation } from "react-router-dom";

// components
import ActivityFeed from "components/Content/ActivityFeed.js";
import FooterAdmin from "components/Footers/Admin/FooterAdmin.js";

// texts for components as props
import activityfeed from "_texts/misc/content/activityfeed.js";
import footeradmin from "_texts/admin/footers/footeradmin.js";

export default function BlogPost() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>

      <ActivityFeed {...activityfeed} />
      <FooterAdmin {...footeradmin} />
    </>
  );
}
