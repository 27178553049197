const textProps = {
  set: "login",
  title: "Try-A-Code",
  socials: [
    { icon: "facebook", button: { href: "#pablo" } },
    { icon: "instagram", button: { href: "#pablo" } },
  ],
  buttonOFF: {
    fullWidth: false,
    outline: false,
    color: "white",
    children: "...",
  },
  buttonON: {
    fullWidth: true,
    outline: true,
    color: "lightBlue",
    children: "Submit Code",
  },
  tempButton: {
    fullWidth: false,
    outline: true,
    color: "light",
    children: "locked",
  },
  resetPassword: {
    to: "/resetpassword",
  },
  createAccount: {
    to: "/register",
  },
  inputs: [
    {
      label: "Enter Code",
      switch: "passcode",
      input: {
        type: "password",
        placeholder: "Passcode",
      },
    },
  ],
};
export default textProps;
