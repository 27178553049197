import React from "react";
import { useLocation } from "react-router-dom";

// sections
import Error1 from "components/Sections/Error/Error1.js";

// texts for sections as props
import error1error500 from "_texts/error/error1error500.js";

export default function Error500() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <Error1 {...error1error500} />
    </>
  );
}
