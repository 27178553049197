import dropdownbutton from "_texts/misc/dropdowns/dropdownbutton.js";
import placeholder0 from "assets/img/bootstrap.jpg";
import placeholder1 from "assets/img/angular.jpg";
import placeholder2 from "assets/img/sketch.jpg";
import placeholder3 from "assets/img/react.jpg";
import placeholder4 from "assets/img/vue.jpg";
import team1 from "assets/img/team-1-800x800.jpg";
import team2 from "assets/img/team-2-800x800.jpg";
import team3 from "assets/img/team-3-800x800.jpg";
import team4 from "assets/img/team-4-470x470.png";


const username = "Username";

const textProps = {
  title: username +"'s Curating Table",
  color: "white",
  head: ["Exhibit", "League", "Batch", "Exhibition Clock", "Ballot"],
  body: [
    [
      {
        exhibit: "Argon Design System",
        image: placeholder0,
        text: "Argon Design System",
      },

      {
        color: "orange",
        text: "pending",
      },
      {
        images: {
          images: [
            {
              image: team1,
              text: "Team 1",
            },
            {
              image: team2,
              text: "Team 2",
            },
            {
              image: team3,
              text: "Team 3",
            },
            {
              image: team4,
              text: "Team 4",
            },
          ],
        },
      },
      {
        text: "60%",
        progress: { value: 60, color: "red" },
      },
      {
        dropdown: { ...dropdownbutton, button: { color: "white", size: "sm" } },
      },
    ],
    [
      {
        exhibit: "Angular Now UI Kit PRO",
        image: placeholder1,
        text: "Angular Now UI Kit PRO",
      },

      {
        color: "emerald",
        text: "completed",
      },
      {
        images: {
          images: [
            {
              image: team1,
              text: "Team 1",
            },
            {
              image: team2,
              text: "Team 2",
            },
            {
              image: team3,
              text: "Team 3",
            },
            {
              image: team4,
              text: "Team 4",
            },
          ],
        },
      },
      {
        text: "100%",
        progress: { value: 100, color: "emerald" },
      },
      {
        dropdown: { ...dropdownbutton, button: { color: "white", size: "sm" } },
      },
    ],
    [
      {
        exhibit: "Black Dashboard Sketch",
        image: placeholder2,
        text: "Black Dashboard Sketch",
      },

      {
        color: "red",
        text: "delayed",
      },
      {
        images: {
          images: [
            {
              image: team1,
              text: "Team 1",
            },
            {
              image: team2,
              text: "Team 2",
            },
            {
              image: team3,
              text: "Team 3",
            },
            {
              image: team4,
              text: "Team 4",
            },
          ],
        },
      },
      {
        text: "73%",
        progress: { value: 73, color: "red" },
      },
      {
        dropdown: { ...dropdownbutton, button: { color: "white", size: "sm" } },
      },
    ],
    [
      {
        exhibit: "React Material Dashboard",
        image: placeholder3,
        text: "React Material Dashboard",
      },

      {
        color: "red",
        text: "delayed",
      },
      {
        images: {
          images: [
            {
              image: team1,
              text: "Team 1",
            },
            {
              image: team2,
              text: "Team 2",
            },
            {
              image: team3,
              text: "Team 3",
            },
            {
              image: team4,
              text: "Team 4",
            },
          ],
        },
      },
      {
        text: "90%",
        progress: { value: 90, color: "teal" },
      },
      {
        dropdown: { ...dropdownbutton, button: { color: "white", size: "sm" } },
      },
    ],
    [
      {
        exhibit: "Vue Material Dashboard",
        image: placeholder4,
        text: "Vue Material Dashboard",
      },

      {
        color: "emerald",
        text: "completed",
      },
      {
        images: {
          images: [
            {
              image: team1,
              text: "Team 1",
            },
            {
              image: team2,
              text: "Team 2",
            },
            {
              image: team3,
              text: "Team 3",
            },
            {
              image: team4,
              text: "Team 4",
            },
          ],
        },
      },
      {
        text: "100%",
        progress: { value: 100, color: "emerald" },
      },
      {
        dropdown: { ...dropdownbutton, button: { color: "white", size: "sm" } },
      },
    ],
  ],
};
export default textProps;
