const textProps = {
  title: "Batch Request",
  button: {
    color: "pink",
    children: "Submit Request",
  },
  inputs: [
    {
      label: "# to Drop(32-1)",
      input: {
        type: "number",
        placeholder: "selecting 2 replaces 32-31 if possible",
        max: "32",
        min: "1",
        border: "borderless",
      },
    },
    {
      label: "Exhibit Title",
      input: {
        type: "string",
        placeholder: "Title of Exhibit",
        border: "borderless",
      },
    },
    {
      label: "Artist",
      input: {
        type: "string",
        placeholder: "Name of Artist",
        border: "borderless",
      },
    },
    {
      label: "Genre",
      input: {
        type: "string",
        placeholder: "Pick a Genre",
        border: "borderless",
      },
    },
  ],
};
export default textProps;
