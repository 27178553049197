import React, { useState } from 'react';
import FooterAdmin from "components/Footers/Admin/FooterAdmin.js";


//images
import background from '../../assets/img/backgrounds/greetings_background.jpg';

//_texts
import footeradmin from "_texts/admin/footers/footeradmin.js";

const Greetings = () => {
  //state for hover
  const [isHover, setIsHover] = useState(false)

  return (
    <>
      <div className="m-4 py-24 px-12 bg-center bg-contain"
        style={{
          backgroundImage: `url(${background})`,
          color: 'white'}}>
          <strong>
          <div className="px-12 my-12 pt-12 text-center" style={{color:'white'}}>
          <div style={{fontSize:'25px'}}>

          </div>

          <div className="mr-4" style={{fontSize:'30px'}}>
            <h1> Greetings </h1>
          </div>

          <div className="mr-4" style={{textAlign: 'right'}}>

          </div>

        </div>
        <div className="py-24">
          <p><small><strong> Original Photo by
          <a href="https://unsplash.com/@anniespratt?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Annie Spratt</a>
          on
          <a href="https://unsplash.com/s/photos/plant?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
          </strong></small></p>
        </div>
      </strong>
      </div>
      <FooterAdmin {...footeradmin} />
    </>
  );
};


export default Greetings;
