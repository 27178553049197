const textProps = {
  title: "Request \"Paper Tape\"",
  submitbutton: {
    children: "Submit",
    size: "sm",
    color: "amber",
  },
  dropdownbutton: {
    children: "Open Form",
    size: "sm",
    color: "lightBlue",
  },
  forms: [
    {
      inputs: [
        {
          label: "Name",
          width: 6,
          input: {
            placeholder: "Enter your name",
            type: "text",
          },
        },
        {
          label: "Email",
          width: 6,
          input: {
            placeholder: "Enter your email",
            type: "text",
          },
        },
        {
          label: "Link #1",
          width: 6,
          input: {
            placeholder: "Enter 1st link",
            type: "text",
          },
        },
        {
          label: "Link #2",
          width: 6,
          input: {
            placeholder: "Enter 2nd link",
            type: "text",
          },
        },
        {
          label: "Street",
          width: 6,
          input: {
            placeholder: "Street for Delivery",
            type: "text",
          },
        },
        {
          label: "City",
          width: 6,
          input: {
            placeholder: "City Name",
            type: "text",
          },
        },
        {
          label: "Zip Code",
          width: 6,
          input: {
            placeholder: "Enter Zip Code",
            type: "text",
          },
        },
        {
          label: "Qty",
          width: 6,
          input: {
            placeholder: "How many you need?",
            type: "text",
          },
        },
      ],
    },
  ],
};

export default textProps;
