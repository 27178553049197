import React, { useState } from 'react';
import FooterAdmin from "components/Footers/Admin/FooterAdmin.js";


//images
import background from '../../assets/img/backgrounds/inviteZer00_background.jpg';

//_texts
import footeradmin from "_texts/admin/footers/footeradmin.js";

const InviteZero0 = () => {
  //state for hover
  const [isHover, setIsHover] = useState(false)

  return (
    <>
      <div className="m-4 py-24 px-12 bg-center bg-contain"
        style={{
          backgroundImage: `url(${background})`,
          color: 'white'}}>
          <strong>
          <div className="px-12 my-12 pt-12 text-center" style={{color:'white'}}>
          <div style={{fontSize:'25px'}}>
            <h1><u><b>DayZer0 Engineering</b></u></h1>
            <br/>
            <h3><em>Join our music community</em></h3>
            <br/>
          </div>

          <div style={{fontSize:'20px'}}>
          <em>
          <h4>We are Artists & Fans for Artists & Fans</h4>
          <br/>
          <h5>Artists submit exhibits</h5>
          <h5>Artists & Fans listen & vote</h5>
          <h5>The Garden Rewards</h5>
          </em>
          <br/>

          <p>Leading exhibits are minted as NFTs for trade at the end of their period of exhibition...</p>
          <br/>
          <p>Here, Artists & Fans collect their favorited exhibits as they listen & vote...</p>
          <br/>
          <p><b>Join this Music Community</b></p>
          <br/>

          <p><strong>Our Mission</strong>: Be the greatest music <i>Competition Platform</i> <b>EVER</b></p>
          <br/>
            <p>In the Garden, reward systems support the growth & discovery of artistry</p>
            <p>at every level</p>
            <br/>
            <p>Currently, joining <i>DayZer0</i> is invite only</p>
          <br/>

          <div className="mr-4" style={{fontSize:'22px'}}>
            <p>Find a <em>DayZer0</em> member > and join.</p>
            <br/>
            <p>To become a DayZer0 member email us.</p>
            <br/>
            <p>Help increase Music Markets</p>
            <br/>
            <p>Be a part of this Music history!</p>
            <br/>
            <p><strong>Don't miss out, join!</strong></p>
            <br/>
            <p>To recieve your code, <b>send an email & let's chat</b>.</p>
            <br/>
            <p>Imagine how the game will change!</p>
            <br/>
            <h5>As an artist, add your engineer to your exhibition records to acknowledge their work</h5>
            <br/>
            <h5>Be more visible to the community</h5>
            <br/><br/>


          </div>

          </div>

          <div className="mr-4" style={{textAlign: 'left', fontSize:'18px'}}>
            <p>let's chat!</p>
            <p><b>2gfitti.music@gmail.com</b></p>
            <br/><br/>
          </div>

          <div className="mr-4" style={{textAlign: 'right'}}>
            <p>Respectfully,</p>
            <p>A. Gardener</p>
          </div>
          {/*
          <div className="mr-4 ml-4" style={{fontSize:'24px'}}>
            <br/>
            <p>Click Below</p>
            <p
              onClick={()=>setIsHover(true)}
              onMouseEnter={()=>setIsHover(true)}
              onMouseLeave={()=>setIsHover(false)}>
              {isHover ? (
                <b><a style={{color:'green'}} href="https://www.indiegogo.com/project/preview/d0a832e6" target="_blank" rel="noopener noreferrer">Learn More & Join</a></b>
              ):(
                <u><a style={{color:'lightblue'}} href="https://www.indiegogo.com/project/preview/d0a832e6" target="_blank" rel="noopener noreferrer">Learn More & Join</a></u>
              )}
            </p>
          </div>
          */}
        </div>
      </strong>
      </div>
      <FooterAdmin {...footeradmin} />
    </>
  );
};


export default InviteZero0;
