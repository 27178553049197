
const textProps = {
  heading3: {
    color: "dark",
    title: "Username's Collection",
    alignment: "left",
  },
  infoAreas: [
    {
      image: require("assets/img/team-1-800x800.jpg").default,
      jobTitle: "#1"
    },
    {
      image: require("assets/img/team-2-800x800.jpg").default,
      jobTitle: "#2"
    },
    {
      image: require("assets/img/team-3-800x800.jpg").default,
      jobTitle: "#3",
    },
    {
      image: require("assets/img/team-3-800x800.jpg").default,
      jobTitle: "#4"
    },
    {
      image: require("assets/img/team-4-470x470.png").default,
      jobTitle: "#5"
    },
    {
      image: require("assets/img/team-3-800x800.jpg").default,
      jobTitle: "Wild Card"
    },
  ],
};
export default textProps;
