import React, {useState, useEffect} from "react";
import { Route, Switch, Redirect, withRouter} from "react-router-dom";
import PropTypes from "prop-types";


//layout(s)
import PremintLayout from "./PremintLayout"

// //admin
import Dashboard from "../site/admin/musicgarden/Dashboard.js";
import ProfileManager from "../site/admin/musicgarden/ProfileManager.js";
import ViewCollection from "../site/admin/musicgarden/ViewCollection.js";
import RecordPage from "../site/admin/musicgarden/premint/RecordPage.js";





const WithAccessLayout = ({match: { url }}) =>{


  return(
    <Switch>
      <Route
        path={`${url}/music_dashboard`}
        exact
        render={() => (
          <React.Fragment>
            <div className="pt-17">
              <Dashboard />
            </div>
          </React.Fragment>
        )}
      />
      {/*
      <Route
        path={`${url}/profilemanager`}
        exact
        render={() => (
          <React.Fragment>
            <div className="pt-17">
              <ProfileManager />
            </div>
          </React.Fragment>
        )}
      />
      */}
      {/*
      <Route
        path={`${url}/exhibitrecords`}
        exact
        render={() => (
          <React.Fragment>
            <div className="pt-17">
              <RecordPage />
            </div>
          </React.Fragment>
        )}
      />
      <Route
        path={`${url}/collection`}
        exact
        render={() => (
          <React.Fragment>
            <div className="pt-17">
              <ViewCollection />
            </div>
          </React.Fragment>
        )}
      />
      */}
      //<Route path="/premint" component={PremintLayout}/>
      <Redirect path="/musicgarden" to="musicgarden/music_dashboard" />
    </Switch>
  );
}; //END of this component

WithAccessLayout.propTypes = { match: PropTypes.object.isRequired }

export default withRouter(WithAccessLayout)
