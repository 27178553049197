import dropdownnavbar from "_texts/misc/dropdowns/dropdownnavbar.js";
import logo from "../../../assets/img/brand/weblogo_MMG.png";

const textProps = {
  color: "black",
  type: "absolute",
  logoImage: logo,
  logoText: "Memory's Music Garden",
  logoLink: { to: "/login" },
  socials: [
    /*
    {
      icon: "facebook",
      text: "Share It",
      link: "https://www.facebook.com",
    },
    */
    {
      icon: "twitter",
      text: "Tweet It",
      link: "https://twitter.com",
    }
  ],
  rightLinks: [
    {
      dropdown: true,
      ...dropdownnavbar,
    },
  ],
};
export default textProps;
