import React, {useState, useEffect} from "react";
import { Route, Switch, withRouter} from "react-router-dom";
import { withAuthenticator } from '@aws-amplify/ui-react';
import PropTypes from "prop-types";


//layout(s)
import WithoutAccessLayout from "./WithoutAccessLayout"
import WithAccessLayout from "./WithAccessLayout"

// //admin
//import MusicGardenDirector from "../site/MusicGardenDirector.js";







const MusicGardenLayout = ({match: { url }}) =>{
//is Good should be music badge chk
  const [isGood, setIsGood] = useState(false)

  useEffect(() =>{
    setIsGood(false)
  },[])


  return(
    <Switch>

      <Route path = {`${url}`} component={WithAccessLayout}/>
    </Switch>
  );
}; //END of this component

MusicGardenLayout.propTypes = { match: PropTypes.object.isRequired }

//export default withRouter(withAuthenticator(MusicGardenLayout))
export default withRouter(MusicGardenLayout)


/*
<Route
  path={`${url}/director`}
  exact
  render={() => (
    <React.Fragment>
      <MusicGardenDirector layout={isGood} />
    </React.Fragment>
  )}
/>
*/
/*
  { isGood ?
  <Route path = {`${url}`} component={WithAccessLayout}/>
  :
  <Route path = {`${url}/visitor`} component={WithoutAccessLayout}/>
  }
  */
