/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { toast } from 'react-toastify';

import {useCurrentFlowUser} from "../../../hooks/use-current-flow-user.hook";
import {showMusicBadge} from "../../../hooks/use-inhouse-music-membership-badge.hook";
import withRedirect from '../../../hoc/withRedirect';

const FooterAdmin = ({ copyright, links, setRedirect, setRedirectUrl }) => {
  const currentBadge = useRecoilValue(showMusicBadge)
  const loggedIn = !currentBadge.walletID ? false : true;

  const [topClass, setTopClass] = useState("block py-4 bg-black")
  const pageString = window.location.hash.split('/').[1];
  const whiteFootList = [
    "postmint",
    "premint"
  ]
  const possLength = whiteFootList.length

  useEffect(() => {
    let i = 0
    let c = 0
    let s = "block py-4 bg-black"
    while (i <= possLength){
     if(whiteFootList[i] === pageString){
       c = 1
       i = possLength + 1
       s = "block py-4 bg-white"
     } else {
       i+=1
     }
    }
    if(c===1){
      setTopClass(s)
    }
  }, [pageString])

  /*//////////////////////////////////////////////// quote of day at LogOut feat
  const quote = async () => {
    const zen ="https://zenquotes.io/api/today/";
      const response = await fetch(zen);
      let zenToday = await response.json();
      console.log(zenToday);
  };

  ////////////////////////////////////////////////*/

  //handle logout
  const handleLogout = async () => {
    //logOut(); need to ask to log out wallet also
    await Auth.signOut();
    toast(`See you later 😄!`,{
      autoClose: 2000,
    });
    setRedirect(true);
  };//END handleLogout

  useEffect(() => {
    setRedirectUrl(`/login`);
  }, [setRedirectUrl]);


  return (
    <>
      <footer className={topClass}>
        <div className="container mx-auto px-4">
          {/* <hr className="mb-4 border-b-1 border-blueGray-200" /> */}
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4">
              <div className="text-center mb-2 md:text-left md:mb-0">
               {/*
                {copyright && copyright.to && (
                  <Link
                    {...copyright}
                    className="text-sm text-blueGray-500 font-semibold py-1 text-center md:text-left"
                  />
                )}
                */}
                {copyright && copyright.to === undefined && (
                  <a
                    {...copyright}
                    className="text-sm text-blueGray-500 font-semibold py-1 text-center md:text-left"
                  />
                )}
              </div>
            </div>
            <div className="w-full md:w-8/12 px-4">
              <ul className="flex flex-wrap list-none md:justify-end  justify-center">
                {links.map((prop, key) => (
                  <li key={key}>
                  {/*
                    {prop.to && (
                      <Link
                        {...prop}
                        className="text-blueGray-700 hover:text-blueGray-900 text-sm font-semibold block py-1 px-3"
                      />
                    )}
                  */}
                    {prop.to === undefined && (
                      <a
                        {...prop}
                        className="text-blueGray-400 hover:text-blueGray-700 text-sm font-semibold block py-1 px-3"
                      />
                    )}
                  </li>
                ))}
                {loggedIn ?
                <li>
                  <Link to="#" onClick={handleLogout} className="text-blueGray-400 hover:text-blueGray-700 text-sm font-bold block py-1"> LogOut </Link>
                </li> : null}
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

FooterAdmin.defaultProps = {
  copyright: {},
  links: [],
};

FooterAdmin.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  setRedirectUrl: PropTypes.func.isRequired,
  // properties to pass to the link object
  // that sits on the left of the footer
  // if you pass a prop named to, the link will be
  // generated using Link from react-router-dom
  copyright: PropTypes.object,
  // array of properties to pass to the link object
  // that are on the right side of the footer
  // if you pass a prop named to, the link will be
  // generated using Link from react-router-dom
  links: PropTypes.arrayOf(PropTypes.object),
};

export default withRedirect(FooterAdmin);
