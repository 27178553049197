import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { useFilePasser } from "../../hooks/use-file-passer.hook.js";

// components
import Button from "../../components/Elements/Button.js";

export default function ImageUpload({
  placeholder,
  changeButton,
  removeButton,
  isDeleteButton,
  position,
  onChange,
  onRemove,
}) {
  // eslint-disable-next-line
  const [imagePreviewURL, setImagePreviewURL] = React.useState(placeholder);
  const fileInput = React.useRef(null);

  const filePasser = useFilePasser().[1]
  const handleImageChange = async (e) => {
    e.preventDefault();
    let info = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(info);
    reader.onload = () => {
      setImagePreviewURL(reader.result);
    }
    reader.onloadend = () => {
      let passfile = {meta: info, file: reader.result, position: position}
      filePasser(passfile)
    };
  };//END handleImageChange
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    setImagePreviewURL(placeholder);
    fileInput.current.value = null;
    let passfile = {meta: null, file: null, position: position}
    filePasser(passfile)
  };
  return (
    <>
      <div className="text-center">
        <input
          className="hidden"
          type="file"
          onChange={handleImageChange}
          ref={fileInput}
        />
        <div className="rounded-full mx-auto shadow-lg rounded max-w-100-px mb-4">
          <img className="rounded-full" src={imagePreviewURL} alt="..." />
        </div>
        {isDeleteButton ? (
          <div>
            <Button {...changeButton} onClick={handleClick} />
            <Button {...removeButton} onClick={handleRemove} />
          </div>
        ):(
          <div>
            <Button {...changeButton} onClick={handleClick} />
          </div>
        )}
      </div>
    </>
  );
}

ImageUpload.defaultProps = {
  changeButton: {},
  removeButton: {},
  onChange: () => {},
  onRemove: () => {},
};

ImageUpload.propTypes = {
  // this is the default image that
  // appears when no image is uploaded
  placeholder: PropTypes.string,
  // props to pass to the change Button component
  changeButton: PropTypes.object,
  // props to pass to the remove Button component
  removeButton: PropTypes.object,
  // this function will get called when
  // clicking the changeButton
  // and it has two parameters, the image, and the image preview
  // example of usage: onChange={(image,imagePreviewURL) => {console.log("image is :",image);console.log("image preview url is: ",imagePreviewURL);}}
  onChange: PropTypes.func,
  // this function will get called when
  // clicking the removeButton, and does not have any params
  // example of usage: onRemove={() => console.log("remove happened")}
  onRemove: PropTypes.func,
};
