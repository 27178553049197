import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
// components
import Input from "../../../components/Elements/Input.js";
import Button from "../../../components/Elements/Button.js";
import Checkbox from "../../../components/Elements/Checkbox.js";
import {useCurrentFlowUser} from "../../../hooks/use-current-flow-user.hook";

import withRedirect from '../../../hoc/withRedirect';

const CardResetPassword = ({
  image,
  title,
  subtitle,
  othersubtitle,
  justEmail,
  inputs,
  checkbox,
  sendButton,
  resetButton,
  tempButton,
  loginAccount,
  createAccount,
  setRedirect,
  setRedirectUrl,
}) => {
  //states
  const [isCredentials, setIsCredentials] = useState({
    email: null,
    code: null,
    new_password: null,
    confirm_password: null
  });
  const [isError, setIsError] = useState({
    email: null,
    code: null,
    new_password: null,
    confirm_password: null,
    cognito: null
  });
  const [isCode, setIsCode] = useState(false);
  const [isErrorArray, setIsErrorArray] = useState([]);

  //Flow Auth
  const [user, loggedIn, {logIn}] = useCurrentFlowUser();

  const chkUserInput = (name, value) => {
    //Regular Expressions
    const emailRegex = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;
    const passwordRegex =  /^(?=.{8,99})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^$*.[\]{}()?"!@#%&/\\,><':;|_~`])(?!.*\s).*$/;
    switch (name) {
      case 'email':
       isError.email = emailRegex.test(value) ? '' : 'Email format not valid!';
        break;
      case 'new_password':
        isError.new_password = passwordRegex.test(value) ? '' : 'Password 8 character minimum.';
        break;
      case 'confirm_password':
        isError.confirm_password = value === isCredentials.new_password ? '' : 'New password & password confirmation must match';
        break;
      default:
        break;
    };
    //create array from error state
      const currentErrorArray = [{
        email: isError.email,
        new_password: isError.new_password,
        confirm_password: isError.confirm_password,
      }];
      setIsErrorArray(currentErrorArray);
  }; //END chkUserInput

  const handleChange = async e => {
    e.preventDefault()
    const {name, value} = e.target;
    switch (name){
      case "email":
        let email = value;
        setIsCredentials({...isCredentials, email})
      break;
      case "code":
        let code = value;
        setIsCredentials({...isCredentials, code})
      break;
      case "new_password":
        let new_password = value;
        setIsCredentials({...isCredentials, new_password})
      break;
      case "confirm_password":
        let confirm_password = value;
        setIsCredentials({...isCredentials, confirm_password})
      break;
      default:
      return
    };
    chkUserInput(name, value)
  }; //END handleChange

  const resetState = () => {
    setIsCredentials({
      email: null,
      code: null,
      new_password: null,
      confirm_password: null
    });
    setIsError({
      email: null,
      code: null,
      new_password: null,
      confirm_password: null,
      cognito: null
    });
    setIsErrorArray([]);
  }


  const handleSubmit = async () => {
    const { code, new_password } = isCredentials;
    if (!loggedIn) {
      //check for wallet & get username
      logIn().then( response => {
        const { addr, loggedIn } = response;
        const username = addr;
        if (!isCode && loggedIn) {
          // Send confirmation code to user's email
          Auth.forgotPassword(username)
              .then(data => {toast.success(`Email Sent 😊. Search your inbox for Memory's Music Garden to locate email.`)})
              .catch(err => {toast("❗ could not complete reset, try again")})
        } else if (isCode && loggedIn) {
            // Collect confirmation code and new password
            Auth.forgotPasswordSubmit(username, code, new_password)
                .then(data => {
                  toast.success(`Reset Complete 😎`);
                  setRedirect(true);
                })
                .catch(err => {toast("❗ could not complete reset, try again")})
        }
      }).catch(err => {
        toast('⛔ no wallet, no log in')
      })
    } else if (loggedIn) {
      try {
        const username = user.addr;
        if (!isCode) {
          // Send confirmation code to user's email
          Auth.forgotPassword(username)
              .then(data => toast.success(`Email Sent 😊`))
              .catch(err => {toast("❗ could not complete reset, try again")})
        } else if (isCode) {
            // Collect confirmation code and new password
            Auth.forgotPasswordSubmit(username, code, new_password)
                .then(data => {
                  toast.success(`Reset Complete 😎`);
                  setRedirect(true)
                })
                .catch(err => {toast("❗ could not complete reset, try again")})
        }
      } catch (err) {
        toast('⛔ no wallet, no log in')
      }
    }
    resetState()
  };//END handleSubmit

  useEffect(() => {
    setIsErrorArray([])
    setIsError({
      email: null,
      code: null,
      new_password: null,
      confirm_password: null,
      cognito: null
    });
  }, [isCode]);

  useEffect(() => {
    setRedirectUrl(`/login`);
  }, [setRedirectUrl]);

  return (
    <>
      <div className="bg-white relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg">
        <div className="pb-6 rounded-t-lg overflow-hidden">
          <img
            src={image}
            alt="..."
            className="w-full rounded-t-lg"
            style={{ marginTop: "-46%" }}
          />
        </div>

        <div className="lg:p-10 px-4 py-4 flex-auto rounded-b-lg">
          <h4 className="text-2xl font-bold text-center">{title}</h4>
          {isErrorArray.map((prop, key)=>{
            return(
              <div key={key} className="text-red-500 text-center mb-3 font-bold">
                <p><small>{prop.email}</small></p>
                <p><small>{prop.new_password}</small></p>
                <p><small>{prop.confirm_password}</small></p>
              </div>
            );
          })}
          <div className="text-center text-blueGray-500 mb-5 mt-1">
            {isCode ? <p>{othersubtitle}</p>  : <p>{subtitle}</p> }
          </div>  <div className="mt-2 inline-block">
              <Checkbox checked={isCode} onChange={({ target }) => setIsCode(target.checked)} {...checkbox} />
            </div>
            {isCode ?
              inputs.map((prop, key) => {
                return (
                  <div key={key} className="relative w-full">
                    <label className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1">
                      {prop.label}
                    </label>
                    <Input onChange={handleChange} name={prop.switch} {...prop.input} />
                  </div>
                  );
                })
              :
              <Input onChange={handleChange} name={justEmail.switch} />
            }
          <div className="text-center mt-6">
            {isCode ? <Button onClick={handleSubmit} {...resetButton} />
              : <Button onClick={handleSubmit} {...sendButton} /> }
          </div>
        </div>
      </div>
      <div className="flex flex-wrap mt-6 relative">
         <div className="w-1/2 text-left">
           <Link {...loginAccount} className="text-blueGray-500 ml-2">
               <small>Login to account</small>
           </Link>
         </div>
         <div className="w-1/2 text-right">
          <Link {...createAccount} className="text-blueGray-500 mr-2">
            <small>Create new account</small>
          </Link>
        </div>
       </div>
    </>
  )
};

CardResetPassword.defaultProps = {
  input: {},
  sendButton: {},
  resetButton: {},
  tempButton: {},
  loginAccount: {},
  createAccount: {},
};

CardResetPassword.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  othersubtitle: PropTypes.string,
  image: PropTypes.string,
  checkbox: PropTypes.object,
  // It is represetnted by the props you
  // can pass to our Input component element
  input: PropTypes.object,
  // It is represetnted by the props you
  // can pass to our Button component element
  sendButton: PropTypes.object,
  resetButton: PropTypes.object,
  tempButton: PropTypes.object,
  // NOTE: the "Forgot password?" text is allready set
  loginAccount: PropTypes.object,
  // NOTE: the "Create new account" text is allready set
  createAccount: PropTypes.object,
  setRedirect: PropTypes.func.isRequired,
  setRedirectUrl: PropTypes.func.isRequired,
};


export default withRedirect(CardResetPassword)
