import React, {useState, useEffect} from "react";
import { toast } from 'react-toastify';
import PropTypes from "prop-types";
import { useRecoilValue } from "recoil";
import { Auth, Storage, API } from "aws-amplify"
import {} from '../../../graphql/mutations';
import placeholderImg from "../../../assets/img/PaperTape_placeholderImg.jpg";
import placeholderImg1 from "../../../assets/img/2imgplaceholder.jpg";
import { showMusicBadge } from "../../../hooks/use-inhouse-music-membership-badge.hook.js";
import { useFilePasser } from "../../../hooks/use-file-passer.hook.js";
// components
import Button from "../../../components/Elements/Button.js";
import Input from "../../../components/Elements/Input.js";
import ImageUpload from "../../../components/Elements/ImageUpload.js";

export default function CardOrderPaperTape({ title, submitbutton, dropdownbutton, forms }) {
  const [isShowForm, setIsShowForm]=useState(false);

  const widths = {
    1: "lg:w-1/12",
    2: "lg:w-2/12",
    3: "lg:w-3/12",
    4: "lg:w-4/12",
    5: "lg:w-5/12",
    6: "lg:w-6/12",
    7: "lg:w-7/12",
    8: "lg:w-8/12",
    9: "lg:w-9/12",
    10: "lg:w-10/12",
    11: "lg:w-11/12",
    12: "lg:w-12/12",
  };

  const handleFormDropdown = () => {
    setIsShowForm(isShowForm => !isShowForm)
  };

  const imgProps = {
    placeholder: placeholderImg,
    changeButton: { children: "Insert Image #1", size: "sm", color: "dark" },
    removeButton: { children: "remove", size: "sm", color: "red", outline: true },
    isDeleteButton: true,
    position: "papertape_imgA",
  };

  const imgProps0 = {
    placeholder: placeholderImg1,
    changeButton: { children: "Insert Image #2", size: "sm", color: "dark" },
    removeButton: { children: "remove", size: "sm", color: "red", outline: true },
    isDeleteButton: true,
    position: "papertape_imgB",
  };

 const [isFormData, setIsFormData] = useState({
   recipient: null,
   email: null,
   link1: null,
   link2: null,
   street: null,
   city: null,
   zip: null,
   qty: null,
   imgA: null,
   imgB: null,
   imglocA: null,
   imglocB: null,
   time: null
 });

 const grabRequestForm = document.getElementById("requestForm")
 const clearForm = () => {
   grabRequestForm.reset()
   setIsFormData({
     recipient: null,
     email: null,
     link1: null,
     link2: null,
     street: null,
     city: null,
     zip: null,
     qty: null,
     imgA: null,
     imgB: null,
     imglocA: null,
     imglocB: null,
     time: null
   })
 };


///////////////////////////////////////////////////////////////////////////////////////////
// pass images from "ImageUpload"
const getImages = useFilePasser().[0];
useEffect(() => {
  if(getImages){
    let position = getImages.position
    let imgObj = {
      meta: getImages.meta,
      file: getImages.file,
      position: position
    }
    switch(position){
      case "papertape_imgA":
        let imgA = imgObj
        setIsFormData({...isFormData, imgA})
      break;
      case "papertape_imgB":
        let imgB = imgObj
        setIsFormData({...isFormData, imgB})
      break;
      default:
    }
  }
}, [getImages])

//chk inhouse to see if user signed in
const musicBadge = useRecoilValue(showMusicBadge)
let isBadgedIn = !musicBadge.musicGardenID ? false : true;
console.log("BadgedIn?", isBadgedIn)


const submitForm = async () => {
  const t = Date.now()
  setIsFormData({...isFormData, t})
  if(!isBadgedIn){
      Auth.currentCredentials()
        .then(d => {
          //
        }).catch(e => {
          //
        })
  }
  //validate & format image object
  let imgs = [isFormData.imgA, isFormData.imgB]
  let b = []
  for( let i = 0; i < imgs.length ; i++){
    if(imgs.[i] === null) continue
    let b64 = imgs.[i].file
    let name = t + imgs.[i].meta.name
    let place = imgs.[i].position
    await fetch(b64)
        .then(async (res) => {
          let blob = await res.blob()
          let n = {name: name, place: place, blob: blob}
          b.push(n)
          })
  }
  //send imgs to papertapebox
  for( let j = 0; j < b.length ; j++){
    //change bucket name when changing env
   const loc = await Storage.put(b.[j].name, b.[j].blob, {
     bucket: "memorymusicgarden567b25e812e44d1f8034e698a7ff67224224-test",
     contentType: b.[j].blob.type,
   });
   console.log("RESULT", loc)
   let placement = b.[j].position
   if(placement === "papertape_imgA"){
     let imglocA = loc //build filel name
     setIsFormData({...isFormData, imglocA})
   }
   if(placement === "papertape_imgB"){
     let imglocB = loc
     setIsFormData({...isFormData, imglocB})
   }
 }//end for
  //send data to dbtable w/ s3 locations
  console.log("REQUEST2", isFormData)

  /*


const todoDetails = {
  name: 'Todo 1',
  description: 'Learn AWS AppSync'
};

const newTodo = await API.graphql({ query: mutations.createTodo, variables: {input: todoDetails}});
*/
  clearForm()
}//submitForm

///////////////////////////////////////////////////////////////////////////////////////////

const handleFormChange = async e => {
  e.preventDefault();
  // switch case and state change for isFormValue
  const {name, value} = e.target;
  switch (name){
    case "Name" :
      let recipient = value;
      setIsFormData({...isFormData, recipient})
    break;
    case "Email":
      let email = value;
      setIsFormData({...isFormData, email})
    break;
    case "Link #1":
      let link1 = value;
      setIsFormData({...isFormData, link1})
    break;
    case "Link #2":
      let link2 = value;
      setIsFormData({...isFormData, link2})
    break;
    case "Street":
      let street = value;
      setIsFormData({...isFormData, street})
    break;
    case "City":
      let city = value;
      setIsFormData({...isFormData, city})
    break;
    case "Zip Code":
      let zip = value;
      setIsFormData({...isFormData, zip})
    break;
    case "Qty":
      let qty = value;
      setIsFormData({...isFormData, qty})
    break;
    default:
      return
  }
}; //END handleFormChange

const handleFormSubmit = async e => {
  e.preventDefault();
  //submitForm();
  /*
  clearForm()
  toast("Request submitted. Thanks! 🎶")
  console.log("Aft-Submit", isFormData)
  */
}; //END handleFormSubmit


  return (
    <>
      <div className="relative flex flex-col w-full mb-6 shadow-lg rounded-lg bg-white">

        <div className="mb-0 p-6 pb-0">
          <div className="text-center flex justify-between items-center">
            <h6 className="text-xl text-blueGray-500 font-bold mb-8">{title}</h6>
            <Button
              {...dropdownbutton}
              onClick={handleFormDropdown}
            >
            { isShowForm ?
            <i className={"fas fa-caret-up transition-all duration-100 ease-in-out transform "}></i>
              :
            <i className={"fas fa-caret-down transition-all duration-100 ease-in-out transform "}></i>
            }
            </Button>
          </div>
        </div>

        { isShowForm ? (
        <div className="flex-auto px-6 pb-6 pt-0">
          <div className="mb-8 mt-8">
            <ImageUpload {...imgProps} />
          </div>
          <div className="mb-4 pt-4">
            <ImageUpload {...imgProps0} />
          </div>
          <form id="requestForm" onSubmit={handleFormSubmit}>
            {forms.map((prop, key) => (
              <div key={key}>
                <h6 className="mt-2 mb-2 font-bold">{prop.title}</h6>
                <hr className="mb-6 border-b-1 border-blueGray-200" />
                <div className="flex flex-wrap -mx-4">
                  {prop.inputs &&
                    prop.inputs.map((inputProp, inputKey) => (
                      <div
                        key={inputKey}
                        className={"w-full px-4 " + widths[inputProp.width]}
                      >
                        <div className="relative w-full mb-3">
                          {inputProp.label && (
                            <label
                              className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1"
                              htmlFor="grid-password"
                            >
                              {inputProp.label}
                            </label>
                          )}
                          {inputProp.input && <Input onChange={handleFormChange} name={inputProp.label} {...inputProp.input} />}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))}
            <div className="mt-8 text-center">
              <Button type="submit" {...submitbutton} /> {/*Add modal for reviewing data record input before final confirm*/}
            </div>
            <div className="text-right">
              <button onClick={handleFormDropdown}>close</button>
            </div>
          </form>
        </div>
        ) : null }
      </div>
    </>
  );
}

const inputsObject = {
  // NOTE: this width only applies on large devices
  width: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  // if you wish, you can send somthing like
  // <span className="mr-2">Label Name</span><span className="text-red-500">*</span>
  // NOTE: the label tag will be auto generated
  label: PropTypes.node,
};

const inputsShape = PropTypes.shape({
  ...inputsObject,
  // props to pass to the Input component
  input: PropTypes.object,
});

const selectsShape = PropTypes.shape({
  ...inputsObject,
  // props to pass to the Select component
  select: PropTypes.object,
});

const inputsTypes = PropTypes.arrayOf(
  PropTypes.oneOfType([inputsShape, selectsShape])
);

CardOrderPaperTape.defaultProps = {
  button: {},
  forms: [],
};

CardOrderPaperTape.propTypes = {
  title: PropTypes.string,
  // props to pass to the Button component
  button: PropTypes.object,

  forms: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      inputs: inputsTypes,
    })
  ),
};
