const textProps = {
  image: require("assets/img/illustrations/linth3.svg").default,
  title: "Reset Password",
  subtitle: "Enter email address to recieve reset code",
  othersubtitle: "Verify Code",
  justEmail: {
    switch: "email",
    leftIcon: "fas fa-envelope",
    type: "email",
    placeholder: "Enter Email address",
  },
  loginAccount: {
    to: "/login",
  },
  createAccount: {
    to: "/register",
  },
  inputs: [
    {
      switch: "code",
      input:{
        type: "text",
        placeholder: "Please enter code",
      }
    },
    {
      label: "New Password",
      switch: "new_password",
      input: {
        type: "password",
        placeholder: "New Password",
      },
    },
    {
      label: "Confirm Password",
      switch: "confirm_password",
      input: {
        type: "password",
        placeholder: "Re-enter New Password",
      },
    }
  ],
  sendButton: {
    color: "dark",
    children: "Send",
  },
  resetButton: {
    color: "dark",
    children: "Reset",
  },
  tempButton: {
    fullWidth: false,
    outline: true,
    color: "light",
    children: "Not in Service",
  },
  checkbox: {
    pretext: "Use Code",
  },
};
export default textProps;
