const textProps = {
  buttonText: "save",
  inputs: [
    {
      labelText: "Name",
      inputProps: {
        placeholder: "Full Name",
        type: "text",
      },
    },
    {
      labelText: "Stage Name",
      inputProps: {
        placeholder: "Alias",
        type: "text",
      },
    },
    {
      labelText: "Email",
      inputProps: {
        placeholder: "Email",
        type: "email",
      },
    },
    {
      labelText: "Phone",
      inputProps: {
        placeholder: "Phone Number",
        type: "phone",
      },
    },
    {
      labelText: "Wallet Address: ",
      inputProps: {
        type: "none",
        infoString: " WalletAddressString"
      }
    },
    {
      labelText: "Public ID: ",
      inputProps: {
        type: "none",
        infoString: "PublicIDInfoString"
      }
    },
  ],
};
export default textProps;
