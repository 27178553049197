import topfive1 from "assets/img/topfive1.png";
import topfive2 from "assets/img/topfive2.png";
import topfive3 from "assets/img/topfive3.png";
import topfive4 from "assets/img/topfive4.png";
import topfive5 from "assets/img/topfive5.png";
import topfiveWC from "assets/img/topfiveWC.png"

const textProps = {
  heading3: {
    color: "dark",
    subtitle: "Collector's",
    title: "Top 5",
    alignment: "left",
  },
  infoAreas: [
    {
      image: topfive1,
      jobTitle: "#1"
    },
    {
      image: topfive2,
      jobTitle: "#2"
    },
    {
      image: topfive3,
      jobTitle: "#3",
    },
    {
      image: topfive4,
      jobTitle: "#4"
    },
    {
      image: topfive5,
      jobTitle: "#5"
    },
    {
      image: topfiveWC,
      jobTitle: "Wild Card"
    },
  ],
};
export default textProps;
