const textProps = {
  inputs: [
    {
      label: "Roles",
      md: 6,
      input: {
        leftIcon: "fas fa-user-circle",
        placeholder: "Select Role to Add",
        type: "text",
      },
    },
    {
      label: "Socials",
      md: 6,
      input: {
        leftIcon: "fas fa-book",
        placeholder: "Add Link to Social Site",
        type: "text",
      },
    },
    {
      label: "Note",
      input: {
        type: "textarea",
        rows: 6,
      },
    },
  ],
  button0: {
    color: "light",
    children: "update",
  },
  button1: {
    color: "light",
    children: "save",
  },
};
export default textProps;
