import {useEffect, useState} from "react"
import {atom, useRecoilState} from "recoil"

//accepts & passes file object between components

export const fileHeld = atom({
  key: "FILE_HOLDER",
  default: {meta: "", file: "", position: ""},
})

export function useFilePasser(passfile, position) {
  const [pass, setPass] = useRecoilState(fileHeld)
  useEffect(() => {
    setPass(passfile)
  }, [])
  return [pass, setPass]
}
