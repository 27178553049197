import React from "react";
import { Route, Switch, Redirect, withRouter} from "react-router-dom";
import PropTypes from "prop-types";


// //admin
import RecordPage from "../site/admin//musicgarden/premint/RecordPage.js";





const PremintLayout = ({ match: { url } }) =>{

  return(
    <Switch>
      <Route
        path={`${url}/exhibitrecords`}
        exact
        render={() => (
          <React.Fragment>
            <div className="pt-17">
              <RecordPage />
            </div>
          </React.Fragment>
        )}
      />
      //<Redirect from="/premint/*" to="/exhibitrecords" />
    </Switch>
  );
}; //END of this component

PremintLayout.propTypes = { match: PropTypes.object.isRequired }

export default withRouter(PremintLayout)
