const textProps = {
 set: "register",
 title: "Register Account",
  socials: [
    { icon: "github", button: { href: "#pablo" } },
    { icon: "facebook", button: { href: "#pablo" } },
  ],
  buttonOFF: {
    fullWidth: false,
    outline: false,
    color: "white",
    size: "sm",
    children: "...",
  },
  buttonON: {
    fullWidth: true,
    outline: true,
    color: "lightBlue",
    size: "sm",
    children: "Create account",
  },
  tempButton: {
    fullWidth: false,
    outline: true,
    color: "light",
    children: "locked",
  },
  resetPassword: {
    to: "/resetpassword",
  },
  loginAccount: {
    to: "/login",
  },
  checkbox: {
    pretext: "I agree to",
    linked: "Terms of Service",
    linkTo: "/terms",
    styleLink: "ml-2 text-sm font-bold text-blueGray-500",
  },
  checkbox1: {
    pretext: "Wallet Guide Read?",
    linked: "Wallet Guide",
    linkTo: "/walletguide",
    styleLink: "ml-2 text-sm font-bold text-lightBlue-500",
  },
  inputs: [
    {
      label: "Username",
      switch: "handle",
      input: {
        type: "text",
        placeholder: "Name or Alias",
      },
    },
    {
      label: "Email",
      switch: "email",
      input: {
        type: "email",
        placeholder: "Email",
      },
    },
    {
      label: "Phone",
      switch: "phone_number",
      input: {
        type: "tel",
        pattern: "[0-9]{10}",
        placeholder: "10 digit U.S. numbers only",
      },
    },
    {
      label: "Password",
      switch: "password",
      input: {
        type: "password",
        placeholder: "Password",
      },
    },
    {
      label: "Confirm Password",
      switch: "confirm_password",
      input: {
        type: "password",
        placeholder: "Re-enter Password",
      },
    },
    {
      label: "Access Code",
      switch: "promo_code",
      input: {
        type: "text",
        placeholder: "Enter Code",
      },
    },
  ],
};
export default textProps;
