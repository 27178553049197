import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FooterAdmin from "components/Footers/Admin/FooterAdmin.js";
import { useLocation } from "react-router-dom";

//images
import background from '../../assets/img/backgrounds/walletGuide_background.jpg';

//_texts
import footeradmin from "_texts/admin/footers/footeradmin.js";



const Terms = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);


  return (
    <>
    <div className="m-4 py-24 px-12 bg-center bg-contain"
      style={{
        backgroundImage: `url(${background})`,
        color: 'white'}}>
        <div className="px-12 my-12 pt-12" style={{height:'auto'}}>
          <div className="text-center">
            <h1><b>Wallet Guide</b></h1>
          </div>
          <br/>
          <p>
            Currently, there are 4 wallets compatible for use across the Flow blockchain; Blocto, Ledger, Finoa, and Kraken.
            Here, the use of either Blocto or Ledger is advised.
            This is because FLOW is not available with Kraken for residents of the US or Canada,
            while Finoa presents a barrier to entry of 10k euros to be eligible for an account.
          </p>
          <br/>
          <p>
            However, if truly interested in <a href="https://www.finoa.io/flow" target="_blank" rel="noopener noreferrer"><u>Finoa</u></a>
            &nbsp;click the following link to learn more.
          </p>
          <br/>
          <h3><b><a href="https://blocto.portto.io/en/" target="_blank" rel="noopener noreferrer"><u>Blocto</u></a></b></h3>
          <p>
            Blocto offers an easy to setup software wallet, and is the most immediate option to get you started.
            Use Blocto to get started now. For more info click the Blocto title/link above.
          </p>
          <br/>
          <div className="mx-12">
            <p>
              <a href="https://port.onflow.org/" target="_blank" rel="noopener noreferrer"><u>Flow Port</u></a>:
            </p>
            <p>
              While using Google Chrome click on the title/link above,
              or compete the registration form for Memory’s Music Garden to navigate to the Flow Port.
              When prompted, select “Sign Up”, choose Blocto, and follow the prompts on the interface.
              Once complete, your account should be created and you’ll be logged into Flow Port.
            </p>
          </div>
          <br/>
          <h3><b><a href="https://shop.ledger.com/pages/hardware-wallets-comparison" target="_blank" rel="noopener noreferrer"><u>Ledger</u></a></b></h3>
          <p>
            Ledger is a hardware wallet designed for those wanting to keep their private keys offline.
            A private key is the password used to authorize outgoing transactions.
            Here, the main idea is to keep said keys in a hardware device that is inaccessible to the internet.
          </p>
          <br/>
          <div className="mx-12">
            <ol>
              <li>
                1.	To begin, ensure Ledger Live is installed on your device and initialize your device.
              </li>
              <br/>
              <li>
                2.	Verify your Ledger device firmware is up to date. Check this by clicking on ‘Manager’ from the side navigation bar.
                (If the install option is not clickable, wait or restart the ledger live app)
              </li>
              <br/>
              <li>
                3.	On the Manager screen in Ledger Live and search for ‘Flow’.
                You should see the Flow App. Install it and follow the instructions on the device.
              </li>
            </ol>
            <br/>
            <p>
              <a href="https://port.onflow.org/" target="_blank" rel="noopener noreferrer"><u>Flow Port</u></a>:
            </p>
            <p>
              With your device connected, using Google Chrome click on the title/link above,
              or compete the registration form for Memory’s Music Garden to navigate to the Flow Port.
              When prompted, select “Sign Up”, choose Ledger, and follow the prompts on your Ledger device.
              Once complete, your account should be created and you’ll be logged into Flow Port.
            </p>
          </div>
          <br/>
          <p>
            <strong>Note</strong>: If you lose your Ledger Device, Flow will not be able to support any type of device recovery.
            Refer to Ledger supported documentation in case of any questions.
          </p>
          <br/>
          <p>
            If you would like to explore this hardware wallet option,
            follow the Ledger title/link above to learn more about what Ledger has to offer.
          </p>
        </div>
      </div>
      <FooterAdmin {...footeradmin} />
    </>
  );
};

Terms.propTypes = { location: PropTypes.object.isRequired };

export default Terms;
