const textProps = {
  text: "Page Menu",
  items: [
    { disabled: true, children: "Pages" },
    //{ href: "/#meminimus/rules", children: "Rules" },
    { href: "/#meminimus/terms", children: "Terms" },
    { href: "/#meminimus/walletguide", children: "Wallet Guide" },
    { href: "/#meminimus/dayZer0", children: "DayZer0 Invite" },
    { href: "/#meminimus/papertape", children: "Request PaperTape" },
  ],
};
export default textProps;
