import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FooterAdmin from "components/Footers/Admin/FooterAdmin.js";
import { useLocation } from "react-router-dom";

//images
import background from '../../assets/img/backgrounds/terms_background.jpg';

//_texts
import footeradmin from "_texts/admin/footers/footeradmin.js";


const Terms = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);


  return (
    <>
    <div className="m-4 py-24 px-12 bg-center bg-contain"
      style={{
        backgroundImage: `url(${background})`,
        color: 'white'}}>
          <div className="px-12 my-12 pt-12" style={{height:'auto'}}>

        <h1>Example Terms of Service</h1>

        <br/><br/>
        <p>
          Welcome to Memory's Music Garden! We provide a platform that attracts communities and creators who invest by sharing what they love.
          Our Terms of Service govern your use of MMG’s services. Terms may be updated as necessary. A change in the effective date means an update has occured.
        </p>
        <br/>
        <h4>Account Registration </h4>
        <p>
          All visitors of the site begin as General users; therefore, the terms of the General User are applicable to all users. Users may create an account as a Creator or Brand.
          A Brand seeks to sponsor contracts, and a Creator seeks to complete events. Users without an account may view any content made available to the general public.
        </p>
        <ul>
          <li>
            <h5>Brands</h5>
            <p>
              If you are a Brand, you may upload your business plan, or any information you consider important in the presentation of your brand.
              To create Tokens, Brands must provide that information which the Securities and Exchange Commission (SEC) requires in accordance with the law. (Your local requirements may dictate what information must be provided)
            </p>
          </li>
          <li>
            <h5>Creators</h5>
            <p>
              Participants must understand the risk of owning equity in early stage companies, including the high likelihood of loss and period of illiquidity.
              By default, when you begin as a Creator, you may only be participating in three events at any given time. Event sponsors may decide to raise or lower this limit as they see fit.
            </p>
          </li>
          <li>
            <h5>General User</h5>
            <p>
              Certain portions of the platform will be visible to users who have not signed up. Nevertheless, those users are bound by this agreement.
              You may browse the public portion of our domain without registering for an account. To use MMG’s services, you need to register. When you register, the information you give us must be accurate and complete.
              Do not impersonate anyone else or violate anyone’s rights. If you don’t follow these rules, we may cancel your account without refund.
            </p>
          </li>
        </ul>




        <p>
          To sign up for an account on your own, you need to at least be 18 years old, or old enough to form a binding contract where you live. If necessary, we may ask for proof of your identity.
          You are responsible for all the activity on your account, and for keeping your password confidential. If you find out that someone’s used your account without permission, quickly let us know.
          We do not sell your personal data to advertisers, and we do not share Personally Identifiable Information (PII) that directly identifies you with advertisers unless you give us specific permission.
          Our Privacy and Data Policy explains how we collect, protect, and use your PII to provide all our services.
        </p>
        <br/>
        <h4>Our Services</h4>
        <p>
          At MMG, there are two main priorities. &nbsp;
          <b>Priority One</b>: Create a world where people are empowered to participate in global markets, by more than financial means alone. &nbsp;
          <b>Priority Two</b>: Distribute the power of growth, by creating a marketplace where participation & promotion is value.<br/><br/>
          To achieve our priorities, we provide the services listed below.
        </p>
        <ul>
          <li>
            <p>Empowerment to use your creativity and participation to access markets</p>
          </li>
          <li>
            <p>Connecting you with people and organizations that care about your brand and creativity</p>
          </li>
          <li>
            <p>Providing you with a personalized experience, support, and a safe global community for all our users</p>
          </li>
          <li>
            <p>Conducting research and developing technologies and services</p>
          </li>
          <li>
            <p>Providing consistently functional and easy to use smart contracts</p>
          </li>
        </ul>
        <br/>
        <h4>SEC Regulation</h4>
        <p>
          Securities laws applicable to private company finance are designed to protect people from making poor investment decisions.
          Unless the sale qualifies for an exemption, Federal securities law requires securities sold in the United States to be registered with the Securities and Exchange Commission (“SEC”).
          Furthermore, many states and foreign countries all have their own regulations governing securities transactions.
          You must make your own assessment regarding the regulatory requirements that may apply to your activities on the platform.
        </p>
        <br/>
        <h4>Your Commitment to Our Community</h4>
        <p>
          By using out Services, you are agreeing to act responsibly in accordance with the Terms outlined here, in our Terms of Service.
          We provide our Services to you and others to achieve our priorities. As such, we require a commitment from you to the following promises, listed in this section.
          <br/>
          You commit to the following:
        </p>
        <ul>
          <li>
            <p>
                To provide accurate information about yourself
            </p>
          </li>
          <li>
            <p>
                To conduct yourself in a professional manner in accordance with our Terms of Service
            </p>
          </li>
          <li>
            <p>
                That you have the right, authority, and capacity to enter into this Agreement on your own behalf and on behalf of any entity for whom you are acting and to abide by all of the terms and conditions contained herein,
                and that if any aspect of your participation violates provisions of the law to which you are subject, you will cease using the services and close your account.
            </p>
          </li>
          <li>
            <p>
              You will create only one Creator account. Each participant may have one Creator account and each Brand may have one account.
              Users may be in control of their own Creator account and an account for each of the Brands they own, or have legal authority to represent
            </p>
          </li>
          <li>
            <p>
              That you shall be solely responsible for maintaining the confidentiality of your password, that you will not give access to your account to others, or transfer your account to anyone else
            </p>
          </li>
          <li>
            <p>
              That you will update your registration information with MMG as needed so that it remains true, correct and complete
            </p>
          </li>
          <li>
            <p>
              You will not create an account if, you are underage; you are a convicted sex offender; we’ve disabled your account for violation of policy and terms; or you are prohibited under applicable laws
            </p>
          </li>
        </ul>
        <br/>
        <h4>What Content you can Share</h4>
        <p>
          We want users to maintain their creativity, and select, support, and share content that is important to them, but not at the expense of the safety or well-being of others or the integrity of our community.
          <br/>
          You therefore agree to the following:
        </p>
        <ul>
          <li>
            <p>
              That you will only provide content where you have adequate rights to all copyrights, trademarks, trade secrets, intellectual property or other material provided by you for display, publication, or use
            </p>
          </li>
          <li>
            <p>
              That you understand, your content may be republished and if you do not have the right to submit content for such use, it may subject you to liability,
              and that MMG will not be responsible or liable for any use of your content by MMG in accordance with this agreement
            </p>
          </li>
          <li>
            <p>
              That you understand, by using the services, you may be exposed to content that might be offensive, harmful, inaccurate or otherwise inappropriate, and that you have no claim against MMG for any such material
            </p>
          </li>
          <li>
            <p>
              That you understand, the service may include advertisements or other similar items, which may be related to content, queries made through the services, or other information,
              and you have no claim against MMG for the placement of advertising or similar content on the platform or other information from the services
            </p>
          </li>
          <li>
            <p>
              That you will only participate through the platform after carefully reviewing and assessing the terms of the event, including the related private placement memorandum,
              smart contract and organizational documents as well as any information provided across the platform
            </p>
          </li>
          <li>
            <p>
              That you will use your own judgment before making any decision to accept any agreement; that you will be solely responsible for complying with applicable law regarding any transaction according to the terms of local law
              (whether the law of a U.S. state or of any foreign government with jurisdiction over you)
            </p>
          </li>
          <li>
            <p>
              That you have reviewed and understand the risks found here, and that you are aware of the risks involved
            </p>
          </li>
          <li>
            <p>
              That you are licensing to us the right to publish all content you upload to MMG, including any comments or other posts you may offer on the site in order to provide the Services.
              Your content will be viewable by other users of the Services and through third party services and websites. You should only provide content that you are comfortable sharing with others
            </p>
          </li>
          <li>
            <p>
              That you acknowledge that you do not rely on the company to monitor or edit the services, or emails initiated by individuals, regardless of whether those individuals are associated with the company,
              and that the services may contain content which you find offensive or which is untrue or misleading and you hereby waive any objections and claims you might have with respect to viewing such content
            </p>
          </li>
          <li>
            <p>
              You agree that any updates for the services may occur at our sole discretion, and shall become part of the Services and subject to this agreement
            </p>
          </li>
        </ul>
        <br/>
        <h4>Permissions you Give</h4>
        <p>
          To provide our Services, you agree to give us permission to use content you create, post, and share. Nothing in these Terms takes away the rights you have to your own content. You are free to share your content with anyone, wherever you want.
          When you share, post, or upload content that is covered by intellectual property rights on or in connection with our platform, you grant us a non-exclusive, transferable, sub-licensable, royalty-free, and worldwide license to host, use, distribute, modify, run, copy, publicly perform or display, translate, and create derivative works of your content.
          This license will end when your content is fully deleted from our systems. You give us permission to use your name and profile picture and information about actions you have taken in connection with ads, offers, and other sponsored content that we display, without requiring compensation.
          You give us permission to download and install updates to the software you use or download.
        </p>
        <br/>
        <p>
          To provide our Services, you agree to gives us permission to contact you by way of contact information provided through this platform, to include toll-free sms/calling, email, social media or other contact capability not listed, but provided by the member.
        </p>
        <br/>
        <h4>Things Not Allowed</h4>
        <p>
          We expect all of our users to help make this a great community to be a part of. As such, agreement to the following is required:
        </p>
        <ul>
          <li>
            <p>
              That you will not upload viruses or malicious code or do anything that could disable, overburden, or impair the proper working or appearance of our platform or service; bypass any measures we’ve put in place to secure the platform services; damage or get unauthorized access to any system, data, password, or other information
            </p>
          </li>
          <li>
            <p>
              That you will not access or collect data from our services by automated means (without our prior permission) or attempt to access data you do not have permission to access; take apart or reverse engineer any aspect of our services in an effort to access things like source code, underlying ideas, or algorithms
            </p>
          </li>
          <li>
            <p>
              That you will not post information you know is false, misleading, or inaccurate. No need to lie
            </p>
          </li>
          <li>
            <p>
              That you will not do anything threatening, abusive, harassing, defamatory, libelous, tortious, obscene, profane, or invasive of another person’s privacy
            </p>
          </li>
          <li>
            <p>
              That you will not distribute unsolicited or unauthorized advertising or promotional material, or any junk mail, spam, or chain letters; that you will not run mail lists, listservs, or any kind of auto-responder or spam on or through the platform
            </p>
          </li>
          <li>
            <p>
              That you will not share anything that is unlawful, misleading, discriminatory or fraudulent; that infringes or violates someone else's rights, or breaches any contract or legal duty you have toward anyone, including their intellectual property rights;
              that violates these terms and policies governing the use of MMG.
            </p>
          </li>
        </ul>
        <p>
          We reserve the right to restrict or remove content that is in violation of these terms at our sole discretion.
          If content is removed we will let you know of any options you have to request review, unless doing so may expose us or others to legal liability; harm our users; compromise or interfere with the integrity or operation of any of our Services or systems;
          where we are restricted due to technical limitations; or where we are prohibited from doing so for legal reasons.
          You are encouraged to report conduct that violates your rights, or our Terms and Policies.
        </p>
        <br/>
        <h4>How Events Work</h4>
        <p>
          To sponsor their events, Brands may use the platform to Tokenize securities, or offer any reasonable award they see fit and are legally allowed to offer.
          When sponsors post events, they are inviting other users to agree to the smart contract that enforces terms and agreements between Brands and Creators. Anyone agreeing to participate in a event is accepting the sponsor’s offer and forming that contract.
          The contract is a direct legal agreement between contracting parties. Specific terms governing that agreement are determined by the contract sponsor at creation. Watch <a href="https://www.youtube.com/channel/UC4a5fCTqhTqES3-gNorKILQ?view_as=subscriber">MMG’s vlog </a>on YouTube to see it in action.
        </p>
        <br/>
        <h4>Account Suspension or Termination</h4>
        <p>
          You may terminate your account at any time through your account settings. However, we may retain certain information as required by law or as necessary for our legitimate business purposes.
          All provisions of this agreement survive termination of an account, including our rights regarding any content you’ve previously submitted to the site, before termination.
          If you have sponsored a event, deleting your account will not automatically remove the event from the site.
        </p>
        <p>
          If we determine that you have clearly, seriously or repeatedly breached our Terms or Policies, we may suspend or permanently disable access to your account.
          We may also suspend or disable your account if you repeatedly infringe other people’s intellectual property rights or where we are required to do so for legal reasons.
          An account will not and cannot be fully terminated until all Tokens and rewards have been claimed and transferred to their legal owner.
        </p>
        <br/>
        <h4>Dispute Resolution & Smart Contracts </h4>
        <br/>
        <ul>
          <li>
            <h5>Disputes</h5>
            <p>
              If you have a dispute, you agree to contact us and attempt resolution informally for at least 30 days, before going to the courts. We reserve the right, but have no obligation, to monitor and/or manage disputes between you and other users of the Services.
              You hereby irrevocably waive any right you may have to trial by jury in any dispute, action, or proceeding. If you have a dispute with other users, you release MMG and hereby agree to indemnify the Company from claims, demands, and damages (actual and consequential) of every kind and nature, known and unknown, arising out of, or in any way connected with, such a dispute.
              In a situation where legal action does arise, all these Terms, rules, policies, or guidelines will be governed by and construed in accordance with the laws of the United States of America, without giving effect to any principles of conflicts of law, and without application of the Uniform Computer Information Transaction Act or the United Nations Convention of Controls for International Sale of Goods.
            </p>
          </li>
          <li>
            <h5>Smart Contracts & Arbitration</h5>
            <p>
              Smart contracts are used to expedite resolution, control the cost of disputes, controversy or claims, and to limit the number of disputes by providing clearly defined terms of agreement, upfront verification of compensation,
              and verification features to determine when a promised action has been performed. Smart contracts are converted to a traditionally readable format (e.g. PDF) so that users may better understand what is being agreed to, before signing the agreement.
              With MMG, smart contracts are a background feature that digitally enforces traditional agreements. It is the user’s responsibility to verify that the smart contract’s performance aligns with the written copy of their agreement. If discrepancies are found, please notify us immediately.
              If it is determined that the contract has not performed in accordance with the written verification of the smart contract, all gains distributed due to the mistake must be returned, while affording fair compensation to creators for their work.
            </p>
          </li>
        </ul>
        <p>
          If you and the company are unable to resolve a dispute through informal negotiations, either you or the company may elect to have the dispute exclusively resolved by binding arbitration. All arbitration proceedings shall be closed to the public and confidential and all records relating thereto shall be permanently sealed, except as necessary to obtain court confirmation of the arbitration award.
          MMG shall have the right to participate by video conference in order to minimize travel and expense burdens.
        </p>
        <br/>
        <h4>Intellectual Property (IP)</h4>
        <br/>
        <ul>
          <li>
            <h5>Your IP</h5>
            <p>
              MMG does not own the content you submit to the platform. However, when you submit your content you are giving us permission to use it how we deem necessary to operate the platform. This content is used to promote you and display opportunities available. When you submit content, or launch a event, you are telling us that it is okay to use that content and information.
              You are responsible for what you submit to the platform, whether publicly or privately. You grant us the right to edit, modify, reformat, excerpt, delete, or translate any of your content. We are not responsible for any mistakes made in any of your content.
            </p>
            <ul>
              <li>
                You will not submit content without the right to do so. Your content may not include copyrighted material you do not own or have permissions to use, or material that is subject to third party proprietary rights you are not legally entitled to post
              </li>
              <li>
                You will pay all royalties and any fees owed to any person or entity, deriving from the use of content you have posted. All required payments are your responsibility
              </li>
              <li>
                You promise that the content you submit will not infringe or violate the rights of any third party, including (without limitation) any privacy rights, publicity rights, copyrights, contract rights, or any other intellectual property or proprietary rights
              </li>
            </ul>
          </li>
          <br/>
          <li>
            <h5>Our IP</h5>
            <p>
              MMG’s content is legally protected, including copyrights, trademarks, service marks, patents, trade secrets, and other rights and laws. You do have the right to use our content for certain personal purposes. You cannot use MMG’s content for anything commercial without written permission.
              A commercial purpose means you intend to use, sell, license, rent, or otherwise exploit content for commercial use, in any way. You agree to respect all copyright and other legal notices, information, and restrictions contained in any content accessed through the platform.
              You must obtain our written permission (or permission under an open source license) to modify, create derivative works of the service, decompile, or otherwise attempt to extract source code from us.
            </p>
          </li>
        </ul>
        <br/>
        <h4>Copyright Policy</h4>
        <p>
          We reserve the right to delete or disable content alleged to be copyright infringement, and to terminate accounts for repeat infringers without notice and at our sole discretion.
          The Digital Millennium Copyright Act (DMCA) defines legal requirements for assisting copyright owners who believe copyright infringement has occurred through acts of third parties over the Internet.
        </p>
        <p>
          If you believe any material on the platform violates your intellectual property rights, in accordance with this agreement, please notify us as soon as possible.
          When contacting MMG about copyright issues, use the contact page and please include the following:
        </p>
        <ul>
          <li>A subject, or first, line that says Copyright Infringement</li>
          <li>A statement and signature that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the copyright owner</li>
          <li>Identity of the infringing material and sufficient information to locate the material</li>
          <li>Identity of the copyright being infringed upon </li>
          <li>Your contact information</li>
        </ul>

        <br/>
        <h4>Our Rights</h4>
        <p>
          We reserve the right to protect the operation and integrity of our platform. We reserve the right to make changes to the platform at our discretion without notice or liability. We reserve the right to cancel accounts or decline to offer our services.
          We have the right to remove, reject, cancel, or legally interrupt any event at our discretion. MMG is not liable for any damages as a result of these actions. We are not obligated to verify due diligence, or endorse posted content.
          We reserve the right to display content from advertisers and sponsors on any page on our platform at any time. Advertisers and sponsors are responsible for ensuring that submitted content is accurate and complies with applicable laws.
          We are not responsible for the illegality, inaccuracy, or problem in an advertiser’s or sponsor’s content. Under no circumstance will we be liable to you for any lost profits, revenues, data, or consequential, special, indirect, exemplary, punitive, or incidental damages.
        </p>
        <br/>
        <h4>Fees</h4>
        <p>
          The use of our Service is not free. Security, platform operations, and other services are covered when users pay to register.
          Sponsors pay fees when their contract is completed and settled amongst participants.
          Brands wishing to have contract fees waived for 1-3yrs may negotiate to do so. You are responsible for paying any additional fees or taxes associated with your use of the platform.
        </p>

      </div>
      </div>
      <FooterAdmin {...footeradmin} />
    </>
  );
};

Terms.propTypes = { location: PropTypes.object };

export default Terms;
