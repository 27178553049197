import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Auth } from "aws-amplify"
//Navbars
import NavbarLinks from "../components/Navbars/NavbarLinks.js";
import navbarlinkslanding1 from "../_texts/presentation/navbars/navbarlinkslanding1.js";
//error
import Error404 from "../site/error/Error404.js";
import Error500 from "../site/error/Error500.js";
//auth
import LoginPublic from "../site/auth/LoginPublic.js";
import RegisterPublic from "../site/auth/RegisterPublic.js";
import ResetPublic from "../site/auth/ResetPublic.js";
//general
//import Rules from "../site/general/Rules.js";
import Terms from "../site/general/Terms.js";
import WalletGuide from "../site/general/WalletGuide.js";
import OrderPaperTape from "../site/general/OrderPaperTape.js";
//temp
import InviteZero0 from "../site/temp/InviteZero0.js";
//layout(s)
import MusicGardenLayout from "./MusicGardenLayout.js"

import {showMusicBadge} from "../hooks/use-inhouse-music-membership-badge.hook";




const AppLayout = () => {
/*
const currentBadge = useRecoilValue(showMusicBadge)
let loggedIn = !currentBadge.walletID ? false : true;
  console.log("AppLayout", loggedIn)
*/
  return(
    <Switch>
    {/* auth */}
      <Route
        path="/login"
        exact
        render={() => (
          <React.Fragment>
            <NavbarLinks {...navbarlinkslanding1} />
            <LoginPublic />
          </React.Fragment>
        )}
      />
      <Route
        path="/register"
        exact
        render={() => (
          <React.Fragment>
            <NavbarLinks {...navbarlinkslanding1} />
            <RegisterPublic />
          </React.Fragment>
        )}
      />
      <Route
        path="/resetpassword"
        exact
        render={() => (
          <React.Fragment>
            <NavbarLinks {...navbarlinkslanding1} />
            <ResetPublic />
          </React.Fragment>
        )}
      />
      {/* error */}
      <Route
        path="/error404"
        exact
        render={() => (
          <React.Fragment>
            <Error404 />
          </React.Fragment>
        )}
      />
      <Route
        path="/error500"
        exact
        render={() => (
          <React.Fragment>
            <Error500 />
          </React.Fragment>
        )}
      />
      {/* general */}
     {/* <Route
        path="/rules"
        exact
        render={() => (
          <React.Fragment>
            <NavbarLinks {...navbarlinkslanding1} />
            <Rules />
          </React.Fragment>
        )}
      /> */}
      <Route
        path="/terms"
        exact
        render={() => (
          <React.Fragment>
            <NavbarLinks {...navbarlinkslanding1} />
            <Terms />
          </React.Fragment>
        )}
      />
      <Route
        path="/walletguide"
        exact
        render={() => (
          <React.Fragment>
            <NavbarLinks {...navbarlinkslanding1} />
            <WalletGuide />
          </React.Fragment>
        )}
      />
      <Route
        path="/papertape"
        exact
        render={() => (
          <React.Fragment>
            <NavbarLinks {...navbarlinkslanding1} />
            <OrderPaperTape />
          </React.Fragment>
        )}
      />
      {/* temp */}
      <Route
        path="/dayZer0"
        exact
        render={() => (
          <React.Fragment>
            <NavbarLinks {...navbarlinkslanding1} />
            <InviteZero0 />
          </React.Fragment>
        )}
      />
      {/*{ loggedIn ?
        <Route path="/musicgarden" component={MusicGardenLayout}/>
      : null }
      { loggedIn ?
        <Redirect from="*" to="musicgarden/director" />
      :
        <Redirect from="*" to="/login" />
      }*/}
      <Route path="/musicgarden" component={MusicGardenLayout}/>
      <Redirect from="*" to="/login" />
    </Switch>
  );
};

export default AppLayout;
