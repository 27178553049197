import React, {useState} from "react";
//import React, {useState, useEffect} from "react"; //useEffect for testing
import PropTypes from "prop-types";
import placeholderImg from "../../../assets/img/PaperTape_placeholderImg.jpg";

// components
import Button from "../../../components/Elements/Button.js";
import Input from "../../../components/Elements/Input.js";
import ImageUpload from "../../../components/Elements/ImageUpload.js";

export default function CardSettingsLarge({ title, savebutton, submitbutton, dropdownbutton, forms, timeOnClock }) {
  const [isShowForm, setIsShowForm]=useState(false);

  const widths = {
    1: "lg:w-1/12",
    2: "lg:w-2/12",
    3: "lg:w-3/12",
    4: "lg:w-4/12",
    5: "lg:w-5/12",
    6: "lg:w-6/12",
    7: "lg:w-7/12",
    8: "lg:w-8/12",
    9: "lg:w-9/12",
    10: "lg:w-10/12",
    11: "lg:w-11/12",
    12: "lg:w-12/12",
  };

  const handleFormDropdown = () => {
    setIsShowForm(isShowForm => !isShowForm)
  };

  const imgProps = {
    placeholder: placeholderImg,
    changeButton: { children: "insert", size: "sm", color: "dark" },
    removeButton: { children: "remove", size: "sm", color: "red", outline: true },
    isDeleteButton: true,
  };
  
 const [isFormData, setIsFormData] = useState({
   stageName: null,
   fullName: null,
   exhibit: null,
   visualArtTitle: null,
   songTitle: null,
   musicLabel: null,
   musicLink1: null,
   musicLink2: null,
   vocalArtists: null,
   musiciansAndInstru: null,
   performers: null,
   songWriters: null,
   musicProducers: null,
   composers: null,
   instrumental: null,
   audioEngineers: null,
   visualCollabs: null,
   lyrics: null,
   aboutExhibit: null,
   imageLocation: null,
   genre: null
 });

// useEffect to test state
/*
 useEffect(()=> {
  console.log("Test", isFormData.musicLabel)
},[isFormData.musicLabel])
*/

 const [isTimeCheckbox, setIsTimeCheckbox] = useState(
   new Array (timeOnClock.length).fill(false)
 );

 const handleTimeChkbox = (spot) => {
   const updateChkboxs = isTimeCheckbox.map((box, boxIndex) =>
     boxIndex === spot ? !box : false
   )
   setIsTimeCheckbox(updateChkboxs)
   const timeNow = Date.now();
   const milliDay = 86400000; //# of milliseconds in a day 1000*3600*24
   const timeSelectArray = [];
   timeSelectArray[0] = milliDay + timeNow;
   timeSelectArray[1] = (3 * milliDay) + timeNow;
   timeSelectArray[2] = (7 * milliDay) + timeNow;
   timeSelectArray[3] = (30 * milliDay) + timeNow;
   const endExhibitionMilli = timeSelectArray[spot];
   const endExhibitionDate = new Date(endExhibitionMilli);

   //Add date to state for beaming up premint records (send time in UTC milliseconds then convert locally for display)

   console.log("Test", endExhibitionDate)
 };//END handleTimeChkbox



const handleFormChange = async e => {
  e.preventDefault();
  // switch case and state change for isFormValue
  const {name, value} = e.target;
  switch (name){
    case "Title of Exhibit" :
      let exhibit = value;
      setIsFormData({...isFormData, exhibit})
    break;
    case "Artist Stage Name":
      let stageName = value;
      setIsFormData({...isFormData, stageName})
    break;
    case "Artist Full Name":
      let fullName = value;
      setIsFormData({...isFormData, fullName})
    break;
    case "Visual Art Title":
      let visualArtTitle = value;
      setIsFormData({...isFormData, visualArtTitle})
    break;
    case "Genre":
      let genre = value;
      setIsFormData({...isFormData, genre})
    break;
    case "Song Title":
      let songTitle = value;
      setIsFormData({...isFormData, songTitle})
    break;
    case "Music Label":
      let musicLabel = value;
      setIsFormData({...isFormData, musicLabel})
    break;
    case "Link #1":
      let musicLink1 = value;
      setIsFormData({...isFormData, musicLink1})
    break;
    case "Link #2":
      let musicLink2 = value;
      setIsFormData({...isFormData, musicLink2})
    break;
    case "Vocal Artists":
      let vocalArtists = value;
      setIsFormData({...isFormData, vocalArtists})
    break;
    case "Musicians & Instruments":
      let musiciansAndInstru = value;
      setIsFormData({...isFormData, musiciansAndInstru})
    break;
    case "Performers":
      let performers = value;
      setIsFormData({...isFormData, performers})
    break;
    case "Songwriters":
      let songWriters = value;
      setIsFormData({...isFormData, songWriters})
    break;
    case "Music Producers":
      let musicProducers = value;
      setIsFormData({...isFormData, musicProducers})
    break;
    case "Composers":
      let composers = value;
      setIsFormData({...isFormData, composers})
    break;
    case "Instrumental":
      let instrumental = value;
      setIsFormData({...isFormData, instrumental})
    break;
    case "Audio Engineers":
      let audioEngineers = value;
      setIsFormData({...isFormData, audioEngineers})
    break;
    case "Visual Collabs":
      let visualCollabs = value;
      setIsFormData({...isFormData, visualCollabs})
    break;
    case "Lyrics":
      let lyrics = value;
      setIsFormData({...isFormData, lyrics})
    break;
    case "About Exhibit":
      let aboutExhibit = value;
      setIsFormData({...isFormData, aboutExhibit})
    break;
    default:
      return
  }
}; //END handleFormChange

const handleFormSave = async e => {
  e.preventDefault();
  console.log("Save")
  //console.log("this is e", e);

}; //END handleFormSave

const handleFormSubmit = async e => {
  e.preventDefault();
  console.log("Submit")
  //console.log("this is e", e);
  /*
  // talk to graphql here
  await addDataRecord().then( response => {
    console.log( "this is addDataRecord response", response)
  })
  */
}; //END handleFormSubmit


  return (
    <>
      <div className="relative flex flex-col w-full mb-6 shadow-lg rounded-lg bg-white">
        <div className="mb-0 p-6 pb-0">
          <div className="text-center flex justify-between items-center">
            <h6 className="text-xl font-bold mb-8">{title}</h6>
            <Button
              {...dropdownbutton}
              onClick={handleFormDropdown}
            >
            { isShowForm ?
            <i className={"fas fa-caret-up transition-all duration-100 ease-in-out transform "}></i>
              :
            <i className={"fas fa-caret-down transition-all duration-100 ease-in-out transform "}></i>
            }
            </Button>
          </div>
          <p><b>Note:</b>
            <span className="ml-1">When listing multiple parties separate each entry with a semicolon ;</span>
          </p>
          <p className={"mb-4 ml-4"}>
            <span>Example: This Name; That Name; Some Name</span>
          </p>
        </div>
        { isShowForm ? (
        <div className="flex-auto px-6 pb-6 pt-0">
        <ImageUpload {...imgProps} />
          <form onSubmit={handleFormSubmit}>
            {forms.map((prop, key) => (
              <div key={key}>
                <h6 className="mt-2 mb-2 font-bold">{prop.title}</h6>
                <hr className="mb-6 border-b-1 border-blueGray-200" />
                <div className="flex flex-wrap -mx-4">
                  {prop.inputs &&
                    prop.inputs.map((inputProp, inputKey) => (
                      <div
                        key={inputKey}
                        className={"w-full px-4 " + widths[inputProp.width]}
                      >
                        <div className="relative w-full mb-3">
                          {inputProp.label && (
                            <label
                              className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1"
                              htmlFor="grid-password"
                            >
                              {inputProp.label}
                            </label>
                          )}
                          {inputProp.input && <Input onChange={handleFormChange} name={inputProp.label} {...inputProp.input} />}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))}
            <div className="mt-0 mb-4 text-center">
            { timeOnClock.map((time, timeKey) => (
              <label className="ml-6 font-semibold" key={timeKey}>
                {time.label}
                <input
                  name={time.label}
                  type="checkbox"
                  className="form-checkbox appearance-none mx-2 w-5 h-5 ease-linear transition-all duration-150 border border-blueGray-300 rounded checked:bg-blueGray-700 checked:border-blueGray-700 focus:border-blueGray-300"
                  checked={isTimeCheckbox[timeKey]}
                  onChange={() => handleTimeChkbox(timeKey)}
                />
              </label>
            ))}
            </div>
            <div className="mt-8 text-center">
              <Button onClick={handleFormSave} {...savebutton} />
              <Button type="submit" {...submitbutton} /> {/*Add modal for reviewing data record input before final confirm*/}
            </div>
            <div className="text-right">
              <button onClick={handleFormDropdown}>close</button>
            </div>
          </form>
        </div>
        ) : null }
      </div>
    </>
  );
}

const inputsObject = {
  // NOTE: this width only applies on large devices
  width: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  // if you wish, you can send somthing like
  // <span className="mr-2">Label Name</span><span className="text-red-500">*</span>
  // NOTE: the label tag will be auto generated
  label: PropTypes.node,
};

const inputsShape = PropTypes.shape({
  ...inputsObject,
  // props to pass to the Input component
  input: PropTypes.object,
});

const selectsShape = PropTypes.shape({
  ...inputsObject,
  // props to pass to the Select component
  select: PropTypes.object,
});

const inputsTypes = PropTypes.arrayOf(
  PropTypes.oneOfType([inputsShape, selectsShape])
);

CardSettingsLarge.defaultProps = {
  button: {},
  forms: [],
};

CardSettingsLarge.propTypes = {
  title: PropTypes.string,
  // props to pass to the Button component
  button: PropTypes.object,

  forms: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      inputs: inputsTypes,
    })
  ),
};
