import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom"

//components
import CardContactUsIcons from "components/Cards/Presentation/CardContactUsIcons";
import CardContactUsTitle from "components/Cards/Presentation/CardContactUsTitle";
import CardInfoIcon from "components/Cards/Presentation/CardInfoIcon";
import ImageUpload from "components/Elements/ImageUpload.js";
import FooterAdmin from "components/Footers/Admin/FooterAdmin.js";

//texts as props
import cardcontactusicons from "_texts/presentation/cards/cardcontactusicons";
import cardcontactustitle from "_texts/presentation/cards/cardcontactustitle";
import cardinfoicon from "_texts/presentation/cards/cardinfoicon";

import placeholderImg from "assets/img/defaultProfileImage.png";
import footeradmin from "_texts/admin/footers/footeradmin.js";




export default function ProfileManager() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const imgProps = {
    placeholder: placeholderImg,
    changeButton: { children: "change", size: "sm", color: "dark" },
    isDeleteButton: false,
  };

  const linkButton = {
    href: "#pablo",
    children: "MyDirectory",
  }

  return(
    <div className="w-full">
      <div className="mb-10 text-center text-5xl text-emerald-900 font-serif font-medium">
        <h1>Profile Manager</h1>
      </div>
      <div className="mb-4">
        <ImageUpload {...imgProps} />
      </div>

        <div className="mx-4">
          <CardContactUsTitle {...cardcontactustitle} />
        </div>
        <div>
          <CardContactUsIcons {...cardcontactusicons} />
        </div>

      <div>
        <CardInfoIcon {...cardinfoicon} />
      </div>
      <div className="mt-4 py-6 ml-6 bg-transparent bg-transparent rounded-b px-4 py-3 border-t border-blueGray-200">
        <Link {...linkButton} className="text-lightBlue-500 text-lg" />
      </div>
      <div>
        <FooterAdmin {...footeradmin} />
      </div>
    </div>
  )
}
