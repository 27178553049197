const textProps = {
  title: "Generate Submission",
  savebutton: {
    children: "Save change",
    size: "sm",
    color: "lightBlue",
  },
  submitbutton: {
    children: "Submit Exhibit",
    size: "sm",
    color: "amber",
  },
  dropdownbutton: {
    children: "Open Form",
    size: "sm",
    color: "lightBlue",
  },
  forms: [
    {
      title: "Submission Details",
      inputs: [
        {
          label: "Title of Exhibit",
          width: 6,
          input: {
            placeholder: "Name of Exhibit",
            type: "text",
          },
        },
        {
          label: "Artist Stage Name",
          width: 6,
          input: {
            placeholder: "Alias of Artist Creating this Exhibit",
            type: "text",
          },
        },
        {
          label: "Artist Full Name",
          width: 6,
          input: {
            placeholder: "Full Name of Artist",
            type: "text",
          },
        },
        {
          label: "Visual Art Title",
          width: 6,
          input: {
            placeholder: "Name of Visual",
            type: "text",
          },
        },
        {
          label: "Genre",
          width: 6,
          input: {
            placeholder: "Enter Art Genres",
            type: "text",
          },
        },
        {
          label: "Song Title",
          width: 6,
          input: {
            placeholder: "Name of Song",
            type: "text",
          },
        },
        {
          label: "Music Label",
          width: 6,
          input: {
            placeholder: "Music Label",
            type: "text",
          },
        },
      ],
    },
    {
      title: "Data Record",
      inputs: [
        {
          label: "Link #1",
          width: 12,
          input: {
            placeholder: "Link to music streaming source",
            type: "text",
          },
        },
        {
          label: "Link #2",
          width: 12,
          input: {
            placeholder: "2nd streaming source (optional)",
            type: "text",
          },
        },
        {
          label: "Vocal Artists",
          width: 12,
          input: {
            placeholder: "Featured Artists",
            type: "text",
          },
        },
        {
          label: "Musicians & Instruments",
          width: 12,
          input: {
            placeholder: "Name & Instrument",
            type: "text",
          },
        },
        {
          label: "Performers",
          width: 12,
          input: {
            placeholder: "Actor, Actress, Dancer etc.",
            type: "text",
          },
        },
        {
          label: "Songwriters",
          width: 12,
          input: {
            placeholder: "Songwriter collabs",
            type: "text",
          },
        },
        {
          label: "Music Producers",
          width: 12,
          input: {
            placeholder: "Producers involved",
            type: "text",
          },
        },
        {
          label: "Composers",
          width: 12,
          input: {
            placeholder: "Composers involved",
            type: "text",
          },
        },
        {
          label: "Instrumental",
          width: 12,
          input: {
            placeholder: "Name of Instrumental",
            type: "text",
          },
        },
        {
          label: "Audio Engineers",
          width: 12,
          input: {
            placeholder: "Audio Engineers involved",
            type: "text",
          },
        },
        {
          label: "Visual Collabs",
          width: 12,
          input: {
            placeholder: "Visual Artists",
            type: "text",
          },
        },
        {
          label: "Lyrics",
          width: 12,
          input: {
            placeholder: "Add Lyrics (optional)",
            type: "textarea",
            rows: 3,
          },
        },
      ],
    },
    {
      title: "Creator's Message",
      inputs: [
        {
          label: "About Exhibit",
          width: 12,
          input: {
            placeholder: "Bonus Info (optional)",
            type: "textarea",
            rows: 4,
          },
        },
      ],
    },
    {
      title: "Time in Exhibition",
    },
  ],
  timeOnClock: [
    {
      label: "24hrs",
    },
    {
      label: "3 Days",
    },
    {
      label: "7 Days",
    },
    {
      label: "30 Days",
    },
  ],
};
export default textProps;
