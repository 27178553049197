import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FooterAdmin from "components/Footers/Admin/FooterAdmin.js";
import CardOrderPaperTape from "components/Cards/ECommerce/CardOrderPaperTape.js";

import cardorderpapertape from "_texts/admin/cards/cardorderpapertape.js";
import { useLocation } from "react-router-dom";

//images
import background from '../../assets/img/backgrounds/requestpapertape_background.jpg';

//_texts
import footeradmin from "_texts/admin/footers/footeradmin.js";


const OrderPaperTape = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);


  return (
    <>
    <div className="m-4 py-24 px-12 bg-center bg-contain"
      style={{
        backgroundImage: `url(${background})`,
        color: 'white'}}>
          <div className="px-12 my-12 pt-12" style={{height:'auto'}}>
          <div className ="">
          </div>
            <div className="mb-8">
              <h1><b>TL;DR</b></h1>
              <p>Scroll to the bottom to quickly order your Paper Tape, or read on to discover what makes a "Paper Tape"
              <br/>Discover side share collabs, music competition opportunities, and more when you order
              <br/>Become Advance Party when you submit</p>
              <br/><br/>
              <h1><b>What is a Paper Tape?</b></h1>
                <p>...just a card with links and images??? Yes & no.<br/>
                Yes. To some a "Paper Tape" will only be a 6x4 card with cover art & scannable links to a music artist's work.<br/>
                However, when linked properly, they become hybrid digitals i.e. physical objects linked to digital effects with real world results.<br/>
                </p>
                <br/><br/>
              <h1><b>Why Paper Tapes?</b></h1>
                <p>For a mixtape-ish era connected to digital capabilities & power</p>

                <br/><br/>
              <h1><b>How to make a Paper Tape?</b></h1>
                <p>+ Google these steps:</p>
                <p>- Step 1: Gain access to a photoshop program.</p>

                <p>- Step 2: Size images to the desired print size. (6x4 inches) </p>
                <p>- Step 3: Use a QR code generator for your links. </p>
                <p>- Step 4: Use photoshop to add QR codes & "Paper Tape" designation to your images.</p>
                <p>- Step 5: Fill out the order form below.</p>
                <br/><br/>
              <h1><b>Side Share Collab</b></h1>
                <p>You get a side...We get a side</p>
                <br/>
                <p>Simply submit 1 image only, we'll do the rest</p>
                <br/>
                <p><strong> Note:</strong> Side Share requests become Advance Party!</p>
                <br/>
                <p>Advance Parties guide the rules of the event</p>
                <br/>
                <p>Use the form below to order your stack, start your collection, and get on the event list.</p>
                <br/>
                <p>#PaperTBot🤖</p>

            </div>
            <div className="w-full px-4">
              <CardOrderPaperTape {...cardorderpapertape} />
            </div>

          </div>
      </div>
      <FooterAdmin {...footeradmin} />
    </>
  );
};

OrderPaperTape.propTypes = { location: PropTypes.object };


export default OrderPaperTape;
