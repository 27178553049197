const textProps = {
  icon: "fas fa-award",
  button: {
    color: "white",
  },
  items: [
    {
      href: "#pablo",
      children: "Up",
    },
    {
      href: "#pablo",
      children: "Down",
    },
    {
      href: "#pablo",
      children: "Collect",
    },
  ],
};
export default textProps;
